import React, { useState } from "react";
import Modal from "react-modal";
import { useSelector } from "react-redux";
import ModalWindow from "../../../components/ModalWindow";
import { Box, colors, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { mockReportDataTeam } from "../../../demo/data/DataReport";
import { Link, useNavigate } from "react-router-dom";
import { translate } from "../../../translations/translate";
import StatusTag from "../../../components/StatusTag";
import Action from "../../../components/Action/index.jsx";
import BreadCrumbs from "../../../components/BreadCrumbs/index.jsx";
import Filter from "../../../components/Filter/index.jsx";
import UserLinkCard from "../../../components/UserLinkCard";
import avatar from "../../../assets/images/avatar.png";
import "../../../assets/fonts/epos-icons/style.css";
import "./styles.sass";

const PageReportTable = ({ ...props }) => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const navigate = useNavigate();
    const [_, setId] = useState();
    const lang = useSelector(state => state.languageReducer.lang);
    const __ = key => translate(lang, key);

    const openModals = (id) => {
        setModalIsOpen(true);
        setId(id);
    };
    const closeModal = () => {
        setModalIsOpen(false);
    };

    const actionNavigate = (id, url) => {
        navigate(url, {
            state: {
                id,
            },
        });
    };

    const columns = [
        {
            field: "ReportNumber",
            headerName: __("Report"),
            flex: 1,
            cellClassName: "mane-column--cell",
            renderCell: ({ row: { ReportNumber } }) => {
                return (
                    <Box display="flex" justifyContent="center">
                        <Link to={props.link}>
                            <Typography
                                fontFamily="Manrope, sans-serif"
                                fontWeight={600}
                                fontSize={14}
                                color={colors.common["black"]}
                            >
                                {ReportNumber || "—"}
                            </Typography>
                        </Link>
                    </Box>
                );
            },
        },
        {
            field: "chequesQuantity",
            headerName: __("Cheques Quantity"),
            flex: 1.5,
            cellClassName: "mane-column--cell",
            renderCell: ({ row: { chequesQuantity } }) => {
                return (
                    <Box display="flex" justifyContent="center">
                        <Link to={props.link}>
                            <Typography
                                fontFamily="Manrope, sans-serif"
                                fontWeight={600}
                                fontSize={14}
                                color={colors.common["black"]}
                            >
                                {chequesQuantity || "—"}
                            </Typography>
                        </Link>
                    </Box>
                );
            },
        },
        {
            field: "cashdeskId",
            headerName: __("Cashdesk"),
            flex: 2,
            cellClassName: "mane-column--cell",
            renderCell: ({ row: { cashdeskId } }) => {
                return (
                    <Box display="flex" justifyContent="center">
                        <Link to={props.link}>
                            <Typography
                                fontFamily="Manrope, sans-serif"
                                fontWeight={600}
                                fontSize={14}
                                color={colors.common["black"]}
                            >
                                {cashdeskId || "—"}
                            </Typography>
                        </Link>
                    </Box>
                );
            },
        },
        // {
        //     field: "closedAt",
        //     headerName: "Date",
        //     flex: 2,
        //     cellClassName: "mane-column--cell",
        //     renderCell: ({row: { closedAt }}) => {
        //         return (
        //             <Box
        //                 display = "flex"
        //                 justifyContent = "center"
        //             >
        //                 <Link to={props.link}>
        //                     <Typography fontFamil={"Manrope" fontWeight={600} fontSize={14} color={colors.common["black"]} , sans-serif>
        //                         {closedAt}
        //                     </Typography>
        //                 </Link>
        //             </Box>
        //         );
        //     }
        // },
        {
            field: "totalDebited",
            headerName: __("Debited"),
            flex: 1.5,
            cellClassName: "mane-column--cell",
            renderCell: ({ row: { totalDebited } }) => {
                return (
                    <Box display="flex" justifyContent="center">
                        <Link to={props.link}>
                            <Typography
                                fontFamily="Manrope, sans-serif"
                                fontWeight={600}
                                fontSize={14}
                                color={colors.common["black"]}
                            >
                                {totalDebited || "—"}
                            </Typography>
                        </Link>
                    </Box>
                );
            },
        },
        {
            field: "totalRefund",
            headerName: __("Refund"),
            flex: 1.5,
            cellClassName: "mane-column--cell",
            renderCell: ({ row: { totalRefund } }) => {
                return (
                    <Box display="flex" justifyContent="center">
                        <Link to={props.link}>
                            <Typography
                                fontFamily="Manrope, sans-serif"
                                fontWeight={600}
                                fontSize={14}
                                color={colors.common["black"]}
                            >
                                {totalRefund || "—"}
                            </Typography>
                        </Link>
                    </Box>
                );
            },
        },
        {
            field: "ownerName",
            headerName: __("Owner"),
            type: "string",
            flex: 2,
            renderCell: (props) => {
                return (
                    <Box display="flex" justifyContent="center">
                        <UserLinkCard
                            userLink="../"
                            avatar={avatar}
                            name={props.value}
                        />
                    </Box>
                );
            },
        },
        {
            field: "status",
            headerName: __("Status"),
            type: "string",
            flex: 1,
            renderCell: (props) => {
                return (
                    <Box display="flex" justifyContent="center">
                        <StatusTag
                            className={`ui-status sm ${
                                props.value === "blocked"
                                    ? "question"
                                    : "active"
                            }`}
                            text={props.value}
                        />
                    </Box>
                );
            },
        },
        {
            field: "action",
            headerName: __("Action"),
            type: "string",
            renderCell: (props) => {
                return (
                    <Box display="flex" justifyContent="center">
                        <Action
                            // onClick={() =>
                            //     actionNavigate(props?.row?.id, "/user/edit")
                            // }
                            onClickView={() =>
                                actionNavigate(props?.row?.id, "/report/view")
                            }
                            openModal={() => openModals(props?.row?.id)}
                        />
                    </Box>
                );
            },
        },
    ];
    return (
        <div className="page page-table-report">
            <Box>
                <BreadCrumbs />
                <Filter text={__("View Report")} createLink="/report/view" />
                <Box
                    sx={{
                        "&": {
                            margin: "40px 0 0",
                            height: "65vh",
                            minHeight: "350px",
                        },
                        "& .name-column--cell": {
                            color: colors.green[300],
                        },
                        "& .MuiDataGrid-footerContainer": {
                            borderTop: "none",
                        },
                    }}
                >
                    <DataGrid
                        sx={{
                            height: "65vh",
                            minHeight: "350px",
                        }}
                        rows={mockReportDataTeam}
                        columns={columns}
                        components={{
                            UITable: <StatusTag />,
                        }}
                        checkboxSelection={true}
                        rowsPerPageOptions={[5, 10, 20, 100]}
                    />
                </Box>
            </Box>
            <Modal
                className="delete-modal"
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
            >
                <div className="modal-bottom">
                    <ModalWindow
                        title={__("Are you sure?")}
                        text={__("Are you sure you want to permanently delete this report?")}
                        onClick={closeModal}
                        Click={closeModal}
                    />
                </div>
            </Modal>
        </div>
    );
};

export default PageReportTable;
