import React, {useEffect, useState} from "react";
import {IMaskInput} from "react-imask";
import {useDispatch, useSelector} from "react-redux";
import {ApiService} from "../../../utils/ApiService";
import {errorToast, successToast} from "../../../utils/toast";
import {useLocation, useNavigate, useParams} from "react-router";
import {translate} from "../../../translations/translate";

import UIAvatar from "../../../components/Avatar";
import Button from "../../../components/global/Button";
import PageHeader from "../../../components/PageHeader";
import useApiHook from "../../../hooks/useApiHook";
import config from "../../../config";
import getStatuses from "../../../utils/globalAPIRequests/getStatuses";
import getUserRoles from "../../../utils/globalAPIRequests/getUserRoles";
import Preloader from "../../../components/Preloader";
import BreadCrumbs from "../../../components/BreadCrumbs";
import SelectComponent from "../../../components/Select";
import DragAndDrop from "../../DragAndDrop";

import "./styles.sass";
import BasicDatePicker from "../../../components/DatePicker";
import {useFormatMask} from "../../../hooks/useFormatMask";
import SearchAutocomplete from "../../../components/SearchAutocomplete/SearchAutocomplete";
import {searchClientsQuery} from "../../../utils/globalAPIRequests/searchClients";
import {searchBusinesses} from "../../../utils/globalAPIRequests/seachBusinesses";
import {Box, colors, Typography} from "@mui/material";
import Filter from "../../../components/Filter";
import {DataGrid} from "@mui/x-data-grid";
import {Link} from "react-router-dom";
import {
    emptyDealerState,
    removePermittedBusiness,
    removePermittedClient,
    updatePermittedBusinesses,
    updatePermittedClients
} from "../../../redux/actions/dealerAction";
import Action from "../../../components/Action";
import ModalWindow from "../../../components/ModalWindow";
import Modal from "react-modal";
import { capitalize } from "../../../utils/capitalize";
import PhoneSelect from "../../../components/PhoneSelect";
import { arraysAreEqual } from "../../../utils/helper";

const PageEdit = () => {
    // USE NAVIGATE
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const PhoneMask = "+998 (00) 000-00-00";

    const phoneEmailMask = [
        {
            mask: PhoneMask,
        },
    ];

    const { state } = useLocation();
    const { id } = useParams();
    const url = `${config.API_BASE_URL}/users/${state?.user?.id || id}`;
    const { data, loading, error } = useApiHook(url);

    const user = data?.data?.docs[0];
    const isDealer = user?.role?.title === "Dealer";

    // GET ROLES
    let roles = getUserRoles();
    useEffect(() => {
        roles && roles.forEach((role) => (role.label = __(role.label)));
    }, [roles]);

    // GET STATUSES
    const statuses = getStatuses("user");
    useEffect(() => {
        statuses &&
            statuses.forEach((status) => (status.label = __(status.label)));
    }, [statuses]);

    const [birthDate, setBirthDate] = useState();
    const [equal, setEqual] = useState(true);
    const [userInitial, setUserInitial] = useState({});
    const [userCurrent, setUserCurrent] = useState({});
    const [avatarUrl, setAvatarUrl] = useState("");
    const [isClientModalOpen, setIsClientModalOpen] = useState(false);
    const [isBusinessModalOpen, setIsBusinessModalOpen] = useState(false);
    const [clientId, setClientId] = useState(null);
    const [businessId, setBusinessId] = useState(null);

    const { token } = useSelector((state) => state.authReducer);
    const controller = useSelector((state) => state.paginationControllerReducer);
    const lang = useSelector((state) => state.languageReducer.lang);
    const {permittedClients, permittedBusinesses} = useSelector((state) => state.dealer);
    const __ = (key) => translate(lang, key);

    const openClientsModalHandler = (id) => {
        setIsClientModalOpen(true);
        setClientId(id);
    };

    const closeClientsModalHandler = () => {
        setIsClientModalOpen(false);
    };

    const removeClientHandler = () => {
        ApiService.deleteEvent(`/users/${user?._id}/client/${clientId}`, token)
          .then((res) => {
              if (res.status === 200) {
                  navigate(`/users/${user?._id}`);
              }
          })
          .catch((error) => {
              errorToast(error);
          });
    };

    const handlePhonesChange = (newPhones) => {
        setUserCurrent({
            ...userCurrent,
            additionalPhones: newPhones
        });
    };

    const openBusinessesModalHandler = (id) => {
        setIsBusinessModalOpen(true);
        setBusinessId(id);
    };

    const closeBusinessesModalHandler = () => {
        setIsBusinessModalOpen(false);
    };

    const removeBusinessHandler = () => {
        ApiService.deleteEvent(`/users/${user?._id}/business/${businessId}`, token)
          .then((res) => {
              if (res.status === 200) {
                  navigate(`/users/${user?._id}`);
              }
          })
          .catch((error) => {
              errorToast(error);
          });
    };

    // Clients table column and row
    const clientColumns = [
        {
            field: "clientName",
            headerName: __("Name"),
            flex: 1,
            cellClassName: "mane-column--cell",
            renderCell: ({ row }) => {
                let fullName = row.firstName + " " + row.lastName;
                if(fullName.length > 37) {
                    fullName = fullName.slice(0, 35) + "...";
                }
                return (
                  <Box display="flex" justifyContent="center">
                      <Link
                        to={`/clients/${row.id}`}
                        state={{
                            client: {
                                id: row.id,
                            },
                        }}
                      >
                          <Typography
                            fontFamily="Manrope, sans-serif"
                            fontWeight={600}
                            fontSize={14}
                            color={colors.common["black"]}
                            textOverflow={"ellipsis"}
                            overflow={"hidden"}
                            whiteSpace={"nowrap"}
                          >
                              {fullName}
                          </Typography>
                      </Link>
                  </Box>
                );
            },
        },
        {
            field: "clientPhone",
            headerName: __("Phone Number"),
            className: "table-phone",
            flex: 1,
            renderCell: ({ row: { phone } }) => {
                return (
                  <Box display="flex" justifyContent="flex-start" width={"100%"}>
                      <Typography
                        fontFamily="Manrope, sans-serif"
                        fontWeight={400}
                        fontSize={14}
                        color={colors.grey[500]}
                        textOverflow={"ellipsis"}
                        overflow={"hidden"}
                        whiteSpace={"nowrap"}
                      >
                          {phone}
                      </Typography>
                  </Box>
                );
            },
        },
        {
            field: "action",
            headerName: __("Action"),
            type: "string",
            renderCell: ({ row }) => {
                return (
                  <Box display="flex" justifyContent="center">
                      <Action
                        onClick={() =>
                          navigate(
                            `/clients/${row.slug || row.id}/edit`,
                            {
                                state: {
                                    client: {
                                        id: row.id,
                                        slug: row.slug,
                                    },
                                },
                            },
                          )
                        }
                        onClickView={() =>
                          navigate(`/clients/${row.slug || row.id}`, {
                              state: {
                                  client: {
                                      id: row.id,
                                      slug: row.slug,
                                  },
                              },
                          })
                        }
                        openModal={() => openClientsModalHandler(row?.id)}
                      />
                  </Box>
                );
            },
        },
    ];

    let clientList = [];

    user?.permittedClients?.map((item) => {
        let clientSingle = {
            firstName: item.name?.first || "—",
            lastName: item.name?.last || "—",
            id: item._id,
            phone: item.phone || "—",
        };
        clientList.push(clientSingle);
    });

    // Businesses table column and row
    const businessColumns = [
        {
            field: "businessName",
            headerName: __("Name"),
            flex: 2.2,
            cellClassName: "mane-column--cell",
            renderCell: ({ row, row: { name, slug } }) => {
                return (
                  <Box
                    display="flex"
                    justifyContent="flex-start"
                    width={"100%"}
                  >
                      <Link
                        to={`/businesses/${slug}`}
                        state={{
                            business: {
                                id: row.id,
                            },
                        }}
                        className="link"
                      >
                          <Typography
                            fontFamily="Manrope, sans-serif"
                            fontWeight={600}
                            fontSize={14}
                            color={colors.common["black"]}
                            textOverflow={"ellipsis"}
                            overflow={"hidden"}
                            whiteSpace={"nowrap"}
                          >
                              {name}
                          </Typography>
                      </Link>
                  </Box>
                );
            },
        },
        {
            field: "number",
            headerName: __("Phone"),
            flex: 2,
            renderCell: ({ row: { phone } }) => {
                return (
                  <Box
                    display="flex"
                    justifyContent="flex-start"
                    width={"100%"}
                  >
                      <Typography
                        fontFamily="Manrope, sans-serif"
                        fontWeight={400}
                        fontSize={14}
                        color={colors.common["black"]}
                        textOverflow={"ellipsis"}
                        overflow={"hidden"}
                        whiteSpace={"nowrap"}
                      >
                          {phone}
                      </Typography>
                  </Box>
                );
            },
        },
        {
            field: "tin",
            headerName: __("TIN/PINFL"),
            flex: 2,
            renderCell: ({ row: { tin } }) => {
                return (
                  <Box
                    display="flex"
                    justifyContent="flex-start"
                    width={"100%"}
                  >
                      <Typography
                        fontFamily="Manrope, sans-serif"
                        fontWeight={400}
                        fontSize={14}
                        color={colors.common["black"]}
                        textOverflow={"ellipsis"}
                        overflow={"hidden"}
                        whiteSpace={"nowrap"}
                      >
                          {tin}
                      </Typography>
                  </Box>
                );
            },
        },
        {
            field: "action",
            headerName: __("Action"),
            type: "string",
            renderCell: ({ row }) => {
                return (
                  <Box display="flex" justifyContent="center">
                      <Action
                        onClick={() =>
                          navigate(
                            `/businesses/${row.slug || row.id}/edit`,
                            {
                                state: {
                                    business: {
                                        id: row.id,
                                        slug: row.slug,
                                    },
                                },
                            },
                          )
                        }
                        onClickView={() =>
                          navigate(`/businesses/${row.slug || row.id}`, {
                              state: {
                                  business: {
                                      id: row.id,
                                      slug: row.slug,
                                  },
                              },
                          })
                        }
                        openModal={() => openBusinessesModalHandler(row?.id)}
                      />
                  </Box>
                );
            },
        },
    ];

    let businessList = [];

    user?.permittedBusinesses?.map((item) => {
        let businessSingle = {
            id: item._id,
            name: item.name || "—",
            slug: item.slug,
            phone: item.phone || "—",
            tin: item.tin || "-"
        };
        businessList.push(businessSingle);
    });

    useEffect(() => {
        if (user) {
            setAvatarUrl(user?.avatar?.fileInfo?.url);
            setBirthDate(user?.birthDate);
            setUserInitial({
                first: user.name?.first,
                last: user.name?.last,
                middle: user.name?.middle,
                email: user.email,
                phone: user.phone,
                avatar: user?.avatar?._id,
                role: user.role?._id,
                status: user?.status,
                birthDate: user?.birthDate,
                permittedClients: user?.permittedClients,
                permittedBusinesses: user?.permittedBusinesses,
                additionalPhones: user?.additionalPhones
            });
            setUserCurrent({
                first: user.name?.first,
                last: user.name?.last,
                middle: user.name?.middle,
                email: user.email,
                phone: user.phone,
                avatar: user?.avatar?._id,
                role: user.role?._id,
                status: user?.status,
                permittedClients: user?.permittedClients,
                permittedBusinesses: user?.permittedBusinesses,
                additionalPhones: user?.additionalPhones
            });
        }
    }, [data]);

    useEffect(() => {
        if (
            userInitial.first === userCurrent.first &&
            userInitial?.avatar === userCurrent?.avatar &&
            userInitial.last === userCurrent.last &&
            userInitial.middle === userCurrent.middle &&
            userInitial.email === userCurrent.email &&
            userInitial.phone === userCurrent.phone &&
            userInitial.role === userCurrent.role &&
            userInitial.status === userCurrent.status &&
            userInitial.birthDate === birthDate &&
            permittedBusinesses.length === 0 &&
            permittedClients.length === 0 &&
            arraysAreEqual(userInitial?.additionalPhones, userCurrent?.additionalPhones)
        ) {
            setEqual(true);
        } else {
            setEqual(false);
        }
    }, [userCurrent, birthDate, permittedBusinesses, permittedClients]);

    // CALLBACK FUNCTIONS
    const onCancelClick = function () {
        navigate("/users");
    };
    const onBirthDateChange = function(e) {
        setBirthDate(e?.$d);
    };

    const [isDragNDropOpen, setIsDragNDropOpen] = useState(false);

    const handleDragNDropOpen = () => {
        setIsDragNDropOpen(!isDragNDropOpen);
    };

    const handleSetAvatar = (url) => {
        setAvatarUrl(url.url);
        setUserCurrent({
            ...userCurrent,
            avatar: url.id,
        });
    };

    const handleClientSearch = async (searchValue) => {
        const result = await searchClientsQuery(searchValue, token);
        return result.filter((resultObj) => !user?.permittedClients.some(item => item._id === resultObj._id));
    };

    const handleBusinessSearch = async (searchValue) => {
        const result = await searchBusinesses(searchValue, token);
        return result.filter((resultObj) => !user?.permittedBusinesses.some(item => item._id === resultObj._id));
    };

    const handleSelectClient = (client) => {
        const { businesses } = client;

        dispatch(updatePermittedClients(client));

        // If there are businesses of the client they will be added
        if (businesses.length > 0) {
            businesses.forEach((business) => {
                dispatch(updatePermittedBusinesses(business));
            });
        }
    };

    const handleSelectBusiness = (business) => {
        dispatch(updatePermittedBusinesses(business));
    };

    const removeSelectedClient = (client) => {
        dispatch(removePermittedClient(client));
    };

    const removeSelectedBusiness = (business) => {
        dispatch(removePermittedBusiness(business));
    };

    const onSubmit = (e) => {
        e.preventDefault();

        if (userCurrent?.phone?.length < 13) {
            errorToast(__("Incorrect length of the phone number"));
            return;
        }

        const body = {
            name: {
                first: userCurrent?.first,
                last: userCurrent?.last,
                middle: userCurrent?.middle,
            },
            phone: userCurrent?.phone,
            email: userCurrent?.email,
            role: userCurrent?.role,
            status: userCurrent?.status,
            avatar: userCurrent?.avatar,
            permittedBusinesses,
            permittedClients,
            birthDate: birthDate? new Date(birthDate)
                .toJSON()
                ?.slice(0, 10)
                .split("-")
                .reverse()
                .join("-"): "",
            additionalPhones: userCurrent?.additionalPhones
        };

        ApiService.patchEvent(`/users/${state?.user.id || id}`, token, body)
            .then((response) => {
                if (response.status === 200) {
                    successToast(__("User successfully updated"));
                    dispatch(emptyDealerState());
                    navigate(`/users/${state?.user.id || id}`);
                }
            })

            .catch((error) => {
                errorToast(__(error.message));
            });
    };

    if (loading)
        return (
            <div>
                <Preloader />
            </div>
        );
    if (error) return <div>{error}</div>;
    const buttons = [
        {
            name: "Back",
            link: "/users",
            state: {},
            className: "outline",
        },
    ];
    // Imask
    let formattedNumber = "";
    return (
        <div>
            <BreadCrumbs
                breadcrumbs={[
                    { label: "Users", link: "/users" },
                    { label: "Edit" },
                ]}
            />

            <div className="page component-page-user-create">
                <form onSubmit={onSubmit} className="page-inner">
                    <div>
                        <PageHeader name={__("Edit User")} buttons={buttons} />
                        <div className="cards">
                            <div className="left-side-cards">
                                {/* Personal Information */}
                                <div className="component-box-card">
                                    <div className="card-header">
                                        <div className="card-info">
                                            <div className="card-icon">
                                                <i className="icon-profile" />
                                            </div>
                                            <div className="card-text">
                                                <p className="card-title">
                                                    {__("Personal Information")}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="input-column">
                                        <UIAvatar
                                            onClick={handleDragNDropOpen}
                                            url={avatarUrl || ""}
                                            fileId={userCurrent.avatar}
                                            onRemoveSuccess={() => navigate(`/users/${user?._id}`)}
                                        />
                                        <div className="input-row">
                                            <div className="input-item">
                                                <div className="component-input">
                                                    <label
                                                        className="input-label"
                                                    >
                                                        <input
                                                            value={userCurrent.first || ""}
                                                            onChange={(e) =>
                                                                setUserCurrent({
                                                                    ...userCurrent,
                                                                    first: capitalize(e.target.value)
                                                                })
                                                            }
                                                            placeholder={__(
                                                                "Enter your name",
                                                            )}
                                                            className="input"
                                                            type="text"
                                                            required
                                                            onInvalid={e => e.target.setCustomValidity(__("Enter your name"))}
                                                            onInput={e => e.target.setCustomValidity("")}
                                                        />
                                                        <span className="input-text">
                                                            {__("First Name")}
                                                        </span>
                                                    </label>
                                                </div>
                                                <div className="component-input">
                                                    <label
                                                        className="input-label"
                                                    >
                                                        <input
                                                            value={userCurrent?.last || ""}
                                                            onChange={(e) =>
                                                                setUserCurrent({
                                                                    ...userCurrent,
                                                                    last: capitalize(e.target.value)
                                                                })
                                                            }
                                                            placeholder={__(
                                                                "Enter your last name",
                                                            )}
                                                            className="input"
                                                            type="text"
                                                            onInvalid={e => e.target.setCustomValidity(__("Enter your last name"))}
                                                            onInput={e => e.target.setCustomValidity("")}
                                                            required
                                                        />
                                                        <span className="input-text">
                                                            {__("Last Name")}
                                                        </span>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="input-item">
                                                <div className="component-input">
                                                    <label
                                                        className="input-label"
                                                    >
                                                        <input
                                                            value={userCurrent?.middle || ""}
                                                            onChange={(e) =>
                                                                setUserCurrent({
                                                                    ...userCurrent,
                                                                    middle: capitalize(e.target.value)
                                                                })
                                                            }
                                                            placeholder={__(
                                                                "Enter your middle name",
                                                            )}
                                                            className="input"
                                                            type="text"
                                                            onInvalid={e => e.target.setCustomValidity(__("Enter your middle name"))}
                                                            onInput={e => e.target.setCustomValidity("")}
                                                            required
                                                        />
                                                        <span className="input-text">
                                                            {__("Middle Name")}
                                                        </span>
                                                    </label>
                                                </div>
                                                <div className="component-input">
                                                    <label
                                                        className="input-label"
                                                    >
                                                        <input
                                                            defaultValue={
                                                                user.email
                                                            }
                                                            onChange={(e) =>
                                                                setUserCurrent({
                                                                    ...userCurrent,
                                                                    email: e
                                                                        .target
                                                                        .value,
                                                                })
                                                            }
                                                            placeholder={__(
                                                                "Enter your email",
                                                            )}
                                                            onInvalid={e => e.target.setCustomValidity(__("Enter your email"))}
                                                            onInput={e => e.target.setCustomValidity("")}
                                                            className="input"
                                                            type="email"
                                                            required
                                                        />
                                                        <span className="input-text">
                                                            {__("Email")}
                                                        </span>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="input-item">
                                                {isDealer ? (
                                                  <PhoneSelect
                                                    phone={userCurrent?.phone}
                                                    setPhone={(phone) => setUserCurrent({
                                                        ...userCurrent,
                                                        phone: useFormatMask(phone),
                                                    })}
                                                    handlePhonesChange={handlePhonesChange}
                                                    options={userCurrent?.additionalPhones}
                                                  />
                                                ) : (
                                                  <div className="component-input">
                                                      <label
                                                        className="input-label"
                                                      >
                                                          <IMaskInput
                                                            value={user.phone}
                                                            onChange={(e) =>
                                                              setUserCurrent({
                                                                  ...userCurrent,
                                                                  phone: useFormatMask(e.target.value),
                                                              })
                                                            }

                                                            onPaste={(e) => {
                                                                const pastedData = e.clipboardData.getData("text");
                                                                const cleanedNumber = pastedData.replace(/\D/g, "");
                                                                formattedNumber = "+998 ";
                                                                if (cleanedNumber.startsWith("998")) {
                                                                    formattedNumber += cleanedNumber.slice(3);
                                                                } else {
                                                                    formattedNumber += cleanedNumber;
                                                                }
                                                                setUserCurrent({
                                                                    ...userCurrent,
                                                                    phone: formattedNumber,
                                                                });
                                                            }}
                                                            autoComplete="off"
                                                            className="mask-on-input card-input input"
                                                            type="tel"
                                                            placeholder="+(998) 99 222 33 44"
                                                            mask={phoneEmailMask}
                                                          />
                                                          <span className="input-text">
                                                            {__("Phone")}
                                                        </span>
                                                      </label>
                                                  </div>
                                                )}
                                                <div className="component-input">
                                                    <label
                                                      className="input-label"
                                                    >
                                                        <span className="input-text date-picker">
                                                            {__(
                                                              "Date of Birth",
                                                            )}
                                                        </span>
                                                        <BasicDatePicker
                                                          value={birthDate || ""}
                                                          onChange={onBirthDateChange}
                                                          selectedDate={birthDate}
                                                          setSelectedDate={setBirthDate}
                                                        />
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* Position */}
                                <div className="component-box-card">
                                    <div className="card-header">
                                        <div className="card-info">
                                            <div className="card-icon">
                                                <i className="icon-profile" />
                                            </div>
                                            <div className="card-text">
                                                <p className="card-title">
                                                    {__("Position")}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="input-item">
                                        <div className="component-input">
                                            <label
                                              className="input-label"
                                            >
                                                <span className="input-text">
                                                    {__("Position")}
                                                </span>
                                                {roles?.length > 0 && (
                                                    <SelectComponent
                                                        value={roles?.find(role => role.value === userCurrent?.role)}
                                                        onChange={(e) =>
                                                            setUserCurrent({
                                                                ...userCurrent,
                                                                role: e.value,
                                                            })
                                                        }
                                                        options={roles}
                                                        name="role"
                                                    />
                                                )}
                                            </label>
                                        </div>
                                        <div className="component-input">
                                            <label
                                                className="input-label"
                                            >
                                                <span className="input-text">
                                                    {__("Status")}
                                                </span>
                                                {statuses?.length > 0 && (
                                                    <SelectComponent
                                                        options={statuses}
                                                        value={statuses?.find(status => status?.value === userCurrent?.status)}
                                                        onChange={(e) =>
                                                            setUserCurrent({
                                                                ...userCurrent,
                                                                status: e.value,
                                                            })
                                                        }
                                                    />
                                                )}
                                            </label>
                                        </div>
                                    </div>
                                    {isDealer && (
                                        <>
                                            <SearchAutocomplete
                                                label="Clients"
                                                selectedItems={permittedClients}
                                                removeSelectedItem={removeSelectedClient}
                                                searchApi={handleClientSearch}
                                                handleClick={handleSelectClient}
                                            />
                                            <SearchAutocomplete
                                                label="Businesses"
                                                selectedItems={permittedBusinesses}
                                                removeSelectedItem={removeSelectedBusiness}
                                                searchApi={handleBusinessSearch}
                                                handleClick={handleSelectBusiness}
                                            />
                                        </>
                                    )}
                                </div>
                                {isDealer && (
                                  <div className="table-flex">
                                      {/* Clients Table */}
                                      <div className="page page-table-branch table branches-table">
                                          <Box>
                                              <Filter
                                                description={__("Clients")}
                                                filters={{
                                                    isBusiness: true,
                                                    isStatus: true,
                                                }}
                                                page={"clients"}
                                                search={""}
                                                setSearch={() => {}}
                                                withoutButton
                                              />
                                              <Box
                                                sx={{
                                                    "&": {
                                                        margin: "0",
                                                    },
                                                    "& .name-column--cell": {
                                                        color: colors.green[300],
                                                    },
                                                    "& .MuiDataGrid-footerContainer": {
                                                        borderTop: "none",
                                                    },
                                                }}
                                              >
                                                  <DataGrid
                                                    rows={clientList}
                                                    rowCount={controller.total}
                                                    page={controller.page}
                                                    pageSize={controller.pageSize}
                                                    columns={clientColumns}
                                                    {...config.TABLE_CONFIG(__)}
                                                  />
                                              </Box>
                                          </Box>
                                      </div>
                                      {/* Businesses Table */}
                                      <div className="page page-table-branch table branches-table">
                                          <Box>
                                              <Filter
                                                description={__("Businesses")}
                                                filters={{
                                                    isBusiness: true,
                                                    isStatus: true,
                                                }}
                                                page={"businesses"}
                                                search={""}
                                                setSearch={() => {}}
                                                withoutButton
                                              />
                                              <Box
                                                sx={{
                                                    "&": {
                                                        margin: "0",
                                                    },
                                                    "& .name-column--cell": {
                                                        color: colors.green[300],
                                                    },
                                                    "& .MuiDataGrid-footerContainer": {
                                                        borderTop: "none",
                                                    },
                                                }}
                                              >
                                                  <DataGrid
                                                    rows={businessList}
                                                    rowCount={controller.total}
                                                    page={controller.page}
                                                    pageSize={controller.pageSize}
                                                    columns={businessColumns}
                                                    {...config.TABLE_CONFIG(__)}
                                                  />
                                              </Box>
                                          </Box>
                                      </div>
                                  </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="component box-save">
                        <div className="not-save-item">
                            {!equal && (
                                <>
                                    <div className="not-save-icon">
                                        <i className="icon-saved" />
                                    </div>
                                    <div className="not-save-text">
                                        <p className="save-text">
                                            {__("Not Saved Yet")}
                                        </p>
                                    </div>
                                </>
                            )}
                        </div>
                        <div className="not-save-button">
                            <Button
                                className="btn outline sm"
                                text={__("Cancel")}
                                onClick={onCancelClick}
                                type="button"
                            />
                            <Button
                                disabled={equal}
                                className="btn sm"
                                text={__("Save")}
                                type="submit"
                            />
                            {isDragNDropOpen &&
                                <DragAndDrop
                                    setter={handleSetAvatar}
                                    closeDragNDrop={handleDragNDropOpen}
                                    uploadType={"avatar"}
                                    docModel={"User"}
                                    isImage={true}
                                />
                            }
                        </div>
                    </div>
                </form>
            </div>
            {/* Client Modal */}
            <Modal
              className="delete-modal"
              isOpen={isClientModalOpen}
              onRequestClose={closeClientsModalHandler}
            >
                <div className="modal-bottom">
                    <ModalWindow
                      title={__("Are you sure?")}
                      text={__(
                        "Are you sure you want to permanently delete this client?",
                      )}
                      onClick={closeClientsModalHandler}
                      onClickDelete={removeClientHandler}
                    />
                </div>
            </Modal>
            {/* Business Modal */}
            <Modal
              className="delete-modal"
              isOpen={isBusinessModalOpen}
              onRequestClose={closeBusinessesModalHandler}
            >
                <div className="modal-bottom">
                    <ModalWindow
                      title={__("Are you sure?")}
                      text={__(
                        "Are you sure you want to permanently delete this business?",
                      )}
                      onClick={closeBusinessesModalHandler}
                      // TODO: delete function realize
                      onClickDelete={removeBusinessHandler}
                    />
                </div>
            </Modal>
        </div>
    );
};

export default PageEdit;
