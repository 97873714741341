import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import reportWebVitals from "./reportWebVitals";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import App from "./App";
import { persistor, store } from "./redux/store";
import { createTheme, ThemeProvider } from "@mui/material";
import Modal from "react-modal";

Modal.setAppElement("#root");

const theme = createTheme({
    typography: {
        fontFamily: [
            "Manrope",
            "-apple-system",
            "sans-serif",
            "Apple Color Emoji",
        ].join(","),
    },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <BrowserRouter>
                    <ThemeProvider theme={theme}>
                        <App />
                    </ThemeProvider>
                    <ToastContainer />
                </BrowserRouter>
            </PersistGate>
        </Provider>
    </React.StrictMode>,
);

reportWebVitals();
