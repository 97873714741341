import Button from "../global/Button";
import { useSelector } from "react-redux";
import { IMaskInput } from "react-imask";
import { translate } from "../../translations/translate";
import { useFormatMask } from "../../hooks/useFormatMask";
import "./styles.sass";

const ModalWithInput = ({title, inputLabel, inputValue, setInputValue, onClose, onConfirm}) => {
  const lang = useSelector((state) => state.languageReducer.lang);
  const __ = (key) => translate(lang, key);

  return (
    <div className="modal-with-input">
      <div className="modal-top">
        <h4>
          <i className="icon-information" />
          {__(title)}
        </h4>
        <i className="icon-x" onClick={onClose} />
      </div>

      <div className="modal-middle page-inner">
        <label className="input-label">
          <IMaskInput
            value={inputValue}
            onAccept={(value) => {
              setInputValue(useFormatMask(value));
            }}
            className="mask-on-input card-input input"
            placeholder="+998 (00) 000-00-00"
            mask="+998 (00) 000-00-00"
          />
          <span className="input-text">
            {__(inputLabel)}
          </span>
        </label>
      </div>

      <div className="modal-buttons">
        <Button
          className="btn outline sm"
          text={__("Cancel")}
          onClick={onClose}
        />
        <Button
          className="btn sm"
          text={__("Confirm")}
          onClick={onConfirm}
        />
      </div>
    </div>
  );
};

export default ModalWithInput;
