export const UZ = {
    Address: "Manzil",
    // ------------------------ AUTHENTICATION PAGE WORDS ------------------------
    Welcome: "Xush kelibsiz",
    "Reset your password": "Parolingizni yangilash",
    "Enter branch name": "Filial nomini kiriting",
    "Enter your phone number to continue":
        "Davom etish uchun telefon raqamingizni kiriting",
    "Enter your phone number and we will send you temporary code to reset your password":
        "Telefon raqamingizni kiriting va parolingizni tiklash uchun vaqtinchalik kodni yuboramiz",
    "Forgot your number or lost it?":
        "Raqamingizni unutdingizmi yoki yo'qotdingizmi?",
    "This phone number is already taken":
        "Bu telefon raqami allaqachon olingan",

    "Enter the code from SMS": "SMS-dan kodni kiriting",
    "Number entered incorrectly?": "Raqam noto'g'ri kiritilganmi?",
    "Incorrect length of the phone number":
        "Telefon raqamning uzunligi noto'g'ri",
    "Send the code again": "Kodni qaytadan jo'natish",
    "You can send the code again after":
        "Kodni vaqt tugaganidan so'ng yana yuborishingiz mumkin",
    "Please enter the verification code we sent to":
        "Iltimos, biz yuborgan tasdiqlash kodini kiriting",
    "Int. Code": "Xalqaro kod",
    "Owner Type": "Egalik turi",
    "Create an account": "Akkaunt ochish",
    "Please fill in your personal information to sign up":
        "Roʻyxatdan oʻtish uchun shaxsiy maʼlumotlaringizni toʻldiring",

    // ------------------------- ERROR PAGE WORDS -----------------------
    "Internal Server Error": "Serverdagi ichki xatolik",
    "Server Error 500. We apologies and we are fixing the problem":
        "Server xatosi 500. Uzr so'raymiz va muammoni hal qilamiz",

    "Page Not Found": "Sahifa topilmadi",
    "The page you are looking for does not exist!":
        "Siz izlayotgan sahifa mavjud emas!",
    "You will receive a tracking link as soon as your order ships.":
        "Buyurtma jo'natilishi bilanoq siz kuzatuv havolasini olasiz.",

    // -------------------------- BUTTON WORDS ----------------------
    "Filter value" : "Filtr qiymati",
    "Columns" : "Ustunlar",
    "Operator" : "Operator",
    "Value" : "Qiymat",
    "contains" : "ichiga olish",
    "equals" : "teng",
    "start with" : "boshlanishi",
    "ends with" : "tugashi",
    "is empty" : "bo'sh",
    "is not empty" : "bo'sh emas",
    "is any of" : "bir nechta",
    "Unsort": "Saralamaslik",
    "Sort by ASC": "O‘sish tartibida saralash",
    "Sort by DESC": "Kamayish tartibida saralash",
    "Hide": "Yashirish",
    "Show columns": "Ustunlarni ko‘rsatish",
    "Find column": "Ustunni qidirish",
    "Column title" : "Ustun sarlavhasi",
    "Hide all": "BARCHASINI YASHIRISH",
    "Show all": "BARCHASINI KO'RSATISH",
    Back: "Orqaga",
    Approve: "Tasdiqlash",
    Save: "Saqlash",
    Submit: "Yuborish",
    Delete: "Oʻchirish",
    Generate: "Yaratish",
    Cancel: "Bekor qilish",
    Confirm: "Tasdiqlash",
    Fiscal: "Fiskal qo'shish",
    "Sign in": "Tizimga kirish",
    "Add Unit": "Birlik qo'shish",
    "Sign up": "Ro'yxatdan o'tish",
    "Add Client": "Mijoz qo'shish",
    "Add Branch": "Filial qo'shish",
    "Add Fiscal": "Fiskal qo'shish",
    "Add Role": "Rol qo'shish",
    "sum": "so'm",
    "Add Cashdesk": "Kassa qo'shish",
    "Add Business": "Biznes qo'shish",
    "Back to home": "Asosiyga qaytish",
    "Add User": "Foydalanuvchi qo'shish",
    "Edit Profile": "Profilni tahrirlash",
    "Sign up new": "Yangi ro'yxatdan o'tish",
    "Reason of Deactivation": "O'chirish sababi",
    "Apply Business Legal Address": "Yuridik manzilni kiritish",

    // ------------------------ INPUT WORDS -----------------------------
    "DD/MM/YYYY": "KK/OO/YY",
    "Enter your company name": "Biznes nomini kiriting",
    Code: "Kod",
    House: "Uy",
    Key: "Kalit",
    City: "Shahar",
    Owner: "Egasi",
    State: "Davlat",
    Comment: "Izoh",
    Phone: "Telefon",
    Status: "Status",
    Street: "Ko'cha",
    District: "Tuman",
    Password: "Parol",
    TIN: "STIR (ИНН)",
    "TIN / PINFL": "STIR (ИНН) / JSHSHR (ПИНФЛ)",
    Select: "Tanlash",
    Region: "Viloyat",
    "First Name": "Ism",
    Position: "Lavozim",
    "Last Name": "Familiya",
    "Middle Name": "Sharif",
    "Fiscal ID": "Fiskal ID",
    PINFL: "JSHSHR (ПИНФЛ)",
    Email: "Elektron pochta",
    ZIPCode: "Pochta indeksi",
    "Type of App": "Ilova turi",
    "MAC Address": "Mac Adress",
    "Branch Name": "Filial nomi",
    "Branch Count": "Filialda",
    "OS Version": "OS versiyasi",
    "Expires At": "Tugash muddati",
    "Business Name": "Biznes nomi",
    "Business Type": "Biznes turi",
    "Fiscal Module": "Fiskal modul",
    "Old Module": "Eski modul",
    "New Module": "Yangi modul",
    "Old Fiscal ID": "Eski Fiskal ID",
    "New Fiscal ID": "Yangi Fiskal ID",
    "Block Date": "Bloklash sanasi",
    "App Version": "Ilova versiyasi",
    "Date of Birth": "Tug'ilgan sana",
    "Your Picture": "Sizning rasmingiz",
    "Expire Date": "Yaroqlilik muddati",
    "Bit Depth": "Tizim bit chuqurligi",
    "Enter you name": "Ismingizni kiriting",
    "Confirm Password": "Parolni tasdiqlang",
    "Choose User": "Foydalanuvchini tanlash",
    "Choose fiscal letter": "Fiskal modul seriyasini tanlang",
    "Deactivation Reason": "O'chirish sababi",
    "Repeat Password": "Parolni qayta kiriting",
    "Device Information": "Qurilma haqida ma'lumot",
    "Manager Information": "Menejer haqida ma'lumot",
    "Version Checked At": "Versiya tekshirilgan sana",
    "Passport Series and Number": "Pasport seriyasi va raqami",
    "Enter your first name": "Ismingizni kiriting",
    "Enter your last name": "Familiyangizni kiriting",
    "Enter your middle name": "Sharifingizni kiriting",
    "Enter your email": "Elektron pochtangizni kiriting",
    "Enter your birth date": "Tug'ilgan kuningizni kiriting",
    "Enter your name": "Введите свое имя",
    "Enter region": "Mintaqani kiriting",
    "Enter city": "Shaharni kiriting",
    "(zero) – 'buying and selling'": " 0 (nol) – 'Sotib olib, sotayotgan'",
    "1 – (one) - 'self-made'": "1 (bir) – 'O‘zi ishlab chiqargan'",
    "2 (two) – 'service provider'": "2 (ikki) – 'Xizmat ko‘rsatuvchi'",
    "Edit Product": "Maxsulotni tahrirlash",
    "Enter district": "Tumanga kiring",
    "Enter street": "Ko'chaga kiring",
    "Enter house": "Uyga kiring",
    "Enter ZIPCode": "ZIP kodini kiriting",
    "Start typing...": "Yozishni boshlang...",
    "Enter title": "Sarlavhani kiriting",
    "Enter title in Russian": "Sarlavhani Rus tilida kiriting",
    "Enter title in Uzbek": "Sarlavhani Oʻzbek tilida kiriting",
    "Enter title in English": "Sarlavhani Ingliz tilida kiriting",
    "Enter description": "Tavsifni kiriting",
    "Enter description in Russian": "Rus tilida tavsifni kiriting",
    "Enter description in Uzbek": "O'zbek tilida tavsifni kiriting",
    "Enter description in English": "Ingliz tilida tavsifni kiriting",

    "Enter ICPS": "MXIK ni kiriting",
    "Enter International Code": "Xalqaro kodni kiriting",
    "Enter Article": "Artikulni kiriting",
    "Enter VAT": "QQS kiriting",
    "Enter Purchase Price": "Xarid narxini kiriting",
    "Enter in stock": "Omborda mavjud",
    "Enter price": "Narxni kiriting",
    "Search Product in Tasnif": "Tasnifda mahsulot qidirish",
    "Search for ICPS, International Code or Title in Russian or Uzbek":
        "Qidirish uchun MXIK, shtrix-Kod yoki mahsulot nomini kiriting",
    "Tasnif Product Info": "Mahsulot haqida ma'lumot Tasnif",
    Marking: "Markirovka",
    "Product Information": "Mahsulot haqida ma'lumot",
    "Title (Russian)": "Rus tilida sarlavha",
    Title: "Nomi",
    "Title (English)": "Ingliz tilida sarlavha",
    "Title (Uzbek)": "Sarlavha o'zbek tilida",
    "Description (Russian)": "Rus tilida tavsif",
    "Description (Uzbek)": "O'zbek tilida tavsif",
    "Description (English)": "Ingliz tilida tavsif",
    "Tax Information": "Soliq ma'lumotlari",
    ICPS: "MXIK",
    "Client Search": "Mijoz qidiruvi",
    "Create Staff Member": "Xodimlarni yaratish ",
    "Receipt #": "Chek #",
    "Intenational Code": "Xalqaro kod",
    Article: "Artikul",
    "Supply Information": "Yetkazib berish haqida ma'lumot",
    "Commitent TIN/PINFL": "Asosiy STIR(ИНН)/JSHSHR (ПИНФЛ)",
    "Price Information": "Narx ma'lumotlari",
    " Price": "Narxi",
    Required: "Talab qilinadi",
    "Not required": "Talab qilinmaydi",
    soums: "sum",
    "No VAT": "QQS yoq",
    "Discount Start Date": "Chegirma boshlanish sanasi",
    "Discount End Date": "Chegirma tugash sanasi",
    "Search Categories": "Kategoriyalarni qidirish ",
    Categories: "Kategoriyalar",
    "Code is Required": "Kod majburiy",
    "Title is Required": "Sarlavha majburiy",
    "Enter business name": "Biznes nomingizni kiriting",
    "Enter state": "Davlatni kiriting",
    "Refunded receipt": "Qaytarilgan chek",

    View: "Ko'rinish",
    Edit: "Tahrirlash",
    Create: "Yaratmoq",
    History: "Tarix",
    "Z-Report": "Z-Hisobot",

    // ------------------------ TABLE WORDS -------------------------
    "rows selected": "tanlangan qatorlar",
    "row selected": "tanlangan qator",
    "Rows per page": "Sahifada qatorlar soni",
    "Current Report Number": "Joriy hisobot raqami",
    "No rows": "Ma'lumot mavjud emas",
    "No results found.": "Ma'lumot mavjud emas",
    "No additional phones": "Qo'shimcha telefonlar mavjud emas",
    Menu: "Menyu",
    "soum": "so'm",
    "Sales": "Sotuvlar",
    "Total Sale Card": "Umumiy sotuvlar kartasi",
    "Total Sale" : "Umumiy sotuvlar soni",
    "Total Sale VAT": "Umumiy sotish QQS",
    "Total Sale Cash": "Umumiy sotish naqd",
    "Total Sale Quantity": "Umumiy sotish miqdori",
    "Refunds": "Qaytarishlar",
    "Total Refund": "Jami Qaytarish",
    "Total Refund VAT": "Jami Qaytarish QQS",
    "Total Refund Cash": "Jami Qaytarish naqd",
    "Total Refund Quantity": "Jami Qaytarish miqdori",
    "Total Refund Card": "Jami Qaytarish Kartasi",
    "Print": "Chop etish",
    "Reports": "Hisobotlar",
    "Report ID": "Hisobot identifikatori",
    "Report Number": "Hisobot raqami",
    Block: "Blok",
    "Opened At" : "Ochilgan vaqti",
    "Closed At" : "Yopilgan vaqti",
    "Report Info" :"Hisobot Ma'lumotlari",
    Credit: "Kredit",
    Shift: "Smena",
    Report: "Hisobot",
    Profile: "Profil",
    Cheques: "Cheklar",
    Search: "Qidirmoq",
    "Log out": "Chiqish",
    Description: "Tavsif",
    Settings: "Sozlamalar",
    "Role Title": "Rol nomi",
    "Role Information": "Rol haqida ma'lumot",
    UserModelPermissions: "Foydalanuvchi uchun ruxsatlar",
    "Created At": "Yaratilgan vaqt",
    ClientModelPermissions: "Kliyent uchun ruxsatlar",
    Products: "Mahsulotlar",
    Product: "Mahsulot",
    Preload: "Avans",
    Service: "Xizmat",
    "Price Range": "Narx diapazoni",
    Permissions: "Ruxsatlar",
    "Create Role": "Rol yaratish",
    "Receipt Number": "Chek Raqami",
    Receipts: "Cheklar",
    Roles: "Rollar",
    Quantity: "Miqdor",
    "Fiscal Sign": "Fiskal belgi",
    "Child Category": "Kichik toifa",
    "Parent Category": "Katta toifa",
    "Legal Address": "Yuridik manzil",
    "View Withdrawal": "Chiqimni ko'rish",
    "View Product": "Maxsulotni ko'rinishi",
    "View Arrival": "Kirimi ko'rish",
    "View Transfer": "O'tkazmani ko'rish",
    "View Report": "Hisobotni ko'rish",
    "View All": "Hammasini ko'rish",
    "Actual Address": "Haqiqiy manzil",
    "Cheques Quantity": "Chek miqdorni",
    "Business Address": "Biznes manzili",
    "Create Product": "Mahsulot yaratish",
    "Create Arrival": "Kirim yaratish",
    "Create Withdrawal": "Chiqim yaratish",
    "Create Transfer": "O'tkazma yaratish",
    "Display Name": "Ko'rsatiladigan ism",
    "Total Information": "To'liq ma'lumot",
    "Business Information": "Biznes ma'lumotlari",
    "Number of Products": "Mahsulotlar soni",
    "Cashdesk License": "Kassa litsenziyasi",
    "Is Oldest Category": "Eng qadimgi toifa",
    "Client Information": "Mijoz ma'lumotlari",
    "Fiscal Information": "Fiskal ma'lumotlari",
    "Applet Information": "Applet ma'lumotlari",
    "Fiscal Module Number": "Fiskal modul raqami",
    "Passport Information": "Pasport ma'lumotlari",
    "User Information": "Foydalanuvchi haqida ma'lumot",
    "Something Went Wrong": "Nimadir noto'g'ri bajarildi",
    "Personal Status Information": "Shaxsiy holat ma'lumotlari",
    "Retrieve Users Info": "Foydalanuvchilar ma'lumotlarini olish",
    "Update Users Info": "Foydalanuvchilar ma'lumotlarini yangilash",
    "Create Users Info": "Foydalanuvchilar ma'lumotlarini yaratish",
    "Delete Users Info": "Foydalanuvchilar ma'lumotlarini o'chirish",

    // ----------------- BLOCKS HEADINGS -------------------------
    Filter: "Filtr",
    "Create Product for": "Mahsulot qo'shish:",
    "Import of the Product": "Mahsulotning Importi",
    "Import of the Fiscal Modules": "Fiskal Modullar Importi",
    of: "ta",
    "Reason:": "Sabab:",
    "Number of failed Products:": "Muvaffaqiyatsiz o'tgan mahsulotlar soni:",
    "products imported successfully": "dan muvaffaqiyatli kiritildi",
    "fiscal modules imported successfully": "dan muvaffaqiyatli kiritildi",
    "Picture successfully deleted": "Rasm muvaffaqiyatli o'chirildi",
    "was not found in Tasnif Products Database": "Tasnif bazasidan topilmadi",
    "was not found in the catalog": "katalogda topilmadi",
    "List of failed products:": "Kiritilmagan mahsulotlar ro'yhati:",
    "List of failed modules:": "Kiritilmagan modullar ro'yhati:",
    "fiscalID or virtualID is not unique in the database / FiscalID is not 14 charecters":
        "Fiskal ID yoki Virtual ID ma'lumotlar bazasida unikal emas / Fiskal ID 14 ta belgidan iborat emas",
    "Fiscal IDs and Virtual IDs should be unique":
        "Fiskal ID va Virtual ID unikal bo'lishi kerak",
    "Download the example of the Excel file for understanding the format of data to be imported":
        "Kiritiladigan Excel fayli na'munasini yuklab oling",
    "Import Products": "Mahsulotlarni Kiritish",
    "Import Fiscal": "Fiskal Ko'chirish",
    Documents: "Hujjatlar",
    "Upload the required documents": "Kerakli hujjatlarni yuklang",
    "Owner's Passport Scan": "Rahbar pasporti nusxasi",
    "Your Company Certificate": "Sizning firmangiz sertifikati",
    "Rent Contract": "Ijara Shartnomasi",
    "Rent Contract (will be used for the first Branch as well)":
        "Ijara shartnomasi (birinchi filial uchun ham qo'llaniladi)",
    "Custom Document": "Maxsus hujjat",
    "Enter description for your document": "Hujjatingiz tavsifini kiriting",
    File: "Fayl",
    "Upload a file": "Faylni yuklang",
    "Drag & Drop or": "Sudrab olib tashlang yoki",
    "Choose file": "Faylni tanlang",
    "Choose a manager!": "Menejerni tanlang!",
    "to upload": "yuklash uchun",
    "You can upload only one document.":
        "Siz bir dona hujjat yuklashingiz mumkin.",
    "Cashdesk EPS": "Kassa EPS",
    "Export Receipts": "Cheklar Eksporti",
    "Export Products": "Maxsulotlar Eksporti",
    "Cashdesk Key": "Kassa kaliti",
    "Virtual Number": "Virtual Raqam",
    "Merchant ID": "Merchant ID",
    "Service ID": "Service ID",
    "Merchant User ID": "Merchant User ID",
    "Secret Key": "Secret Key",
    "Branch ID": "Branch ID",
    "User Name": "User Name",
    Dealer: "Diler",
    "Edit Role": "Edit Role",
    "Add Product Category": "Kategoriya Qo'shish",
    "Add Product": "Mahsulot qo'shish",
    "Create User": "Foydalanuvchi Yaratish",
    "Category Information": "Kategoriya haqida ma'lumot",
    "Category Settings": "Kategoriya sozlamalari",
    "Parent category": "Asosiy kategoriya",
    "View Category": "Kategoriyani ko'rish",
    "Child Categories": "Ichki Kategoriya",
    Home: "Asosiy",
    Here: "Bu yerga",
    Clients: "Mijozlar",
    Cashdesks: "Kassalar",
    Branches: "Filiallar",
    Category: "Kategoriya",
    Loading: "Yuklanmoqda",
    "View Cart": "Savatni ko'rish",
    Businesses: "Bizneslar",
    Users: "Foydalanuvchilar",
    Notifications: "Bildirishnomalar",
    Date: "Sana",
    Amount: "Miqdori",
    Labeling: "Belgilash",
    Barcode: "Shtrix kod",
    Source: "Manba",
    Receiver: "Qabul qiluvchi",
    Sold: "Sotilgan",
    Utilized: "Utilizatsiyalashtirilgan",
    "Total Count": "Umumiy soni",
    "The same branches have been selected":
        "Ikkala maydonda ham bir xil filial tanlangan",
    "Source Branch": "Jo'natuvchi filial",
    "Receiver Branch": "Qabul qiluvchi filial",
    "Production Date": "Ishlab chiqarilgan sana",
    "Expiry Date": "Yaroqlilik Muddati",
    "Selling Price": "Sotish Narxi",
    "Total Sum": "Umumiy Summa",
    "Number of Items": "Mahsulotlar Soni",
    "Total Number of Items": "Mahsulotlar Soni",
    "Invoice Number": "Hisob-Faktura Raqami",
    "Supplier's INN": "Yetkazib beruvchi INNsi",
    "In Stock": "Omborda",
    "Fiscal Modules": "Fiskal modullar",
    "Fiscal ModulesID": "Fiskal modullar ID",
    "Upload Your Picture": "Rasmingizni yuklang",
    "Personal Information": "Shaxsiy ma'lumotlar",
    "Fiscal Module Information": "Fiskal modul ma'lumotlari",
    "Users credentials": "Foydalanuvchilar hisob ma'lumotlari",
    "Select your desired login option:": "Kerakli login variantini tanlang:",
    "The product is not selected or not all fields are filled in": "Товар не выбран или заполнены не все поля",
    "Both User and Client have been found.":
        "Foydalanuvchi ham, mijoz ham topildi.",
    "Create Fiscal Module": "Fiskal modul yaratish",
    "Create Business": "Biznes yaratish",
    "Create Client": "Kliyent Yaratish",
    "Create Branch": "Filial yaratish",
    "Branch Information": "Filial haqida ma'lumot",
    "Branch Address": "Filial manzili",
    "Create Cashdesk": "Kassa yaratish",
    "Cashdesk Information": "Kassa ma'lumotlari",
    "View Fiscal Module": "Fiskal modul",
    "Edit Fiscal Module": "Fiskal modulni tahrirlash",
    "View User": "Foydalanuvchi",
    "Edit User": "Foydalanuvchini tahrirlash",
    "View Client": "Mijoz",
    "Edit Client": "Mijozni tahrirlash",
    "View Business": "Biznes",
    "Edit Business": "Biznesni tahrirlash",
    "View Branch": "Filial",
    "Edit Branch": "Filialni tahrirlash",
    "View Cashdesk": "Kassa",
    "Edit Cashdesk": "Kassani tahrirlash",
    "Edit Category": "Kategoriyani Tahrirlash",
    "Create Product Category for": "Mahsulot toifasini yaratish:",
    "Are you sure you want to permanently delete this staff member?":
        "Ushbu xodimni butunlay o'chirishni xohlayotganingizga aminmisiz?",
    "You are trying to delete a Products Category. When deleting a category all products within will be permanently deleted too. Are you sure you want to proceed?":
        "Siz maxsulotlar kategoriyasini o'chirib yuboryapsiz. Kategoriya o'chirilgan payt unga tegishli bo'lgan mahsulotlar ham o'chib ketadi. Davom etasizmi?",
    Uzbek: "O'zbekcha",
    English: "Inglizcha",
    Russian: "Ruscha",
    Preferences: "Afzalliklar",
    User: "Foydalanuvchi",
    Client: "Klient",
    Exit: "Chiqish",
    "Users information": "Foydalanuvchi haqida ma'lumot",
    "Enter a comment": "Fikr kiriting",
    Staff: "Xodimlar",
    "Add Staff Member": "Xodim qo'shish",

    // ------------------------------ TABLE WORDS -----------------------------
    Communicator: "Kommunikator",
    Mobile: "Mobil",
    VAT: "QQS",
    IE: "YATT",
    LLC: "MCHJ",
    JSC: "AJ",
    PC: "XK",
    SUE: "DUK",
    Name: "Ism",
    Role: "Rol",
    Type: "Tur",
    Test: "Test",
    Total: "Jami",
    Free: "Ozod",
    Price: "Narx",
    Image: "Rasm",
    Card: "Karta",
    Active: "Faol",
    Sale: "Sotish",
    sale: "Sotish",
    Cash: "Naqd pul",
    Branch: "Filial",
    Action: "Harakat",
    Amounts: "Miqdor",
    amount: "(so'mlar)",
    percentage: "(foiz)",
    Cashdesk: "Kassa",
    Question: "Savol",
    Business: "Biznes",
    Director: "Rahbar",
    Bookkeeper: "Buxgalter",
    "Support Head": "Qo'llab-quvvatlash boshlig'i",
    "Support Assistant": "Qo'llab-quvvatlash yordamchi",
    "Project Manager": "Loyihalar bo'yicha menejer",
    Developer: "Dasturchi",
    Saved: "Saqlangan",
    Manager: "Menejer",
    Cashier: "Kassir",
    Percentage: "Foiz",
    Defected: "Yaroqsiz",
    Discount: "Chegirma",
    Blocked: "Bloklangan",
    Deleted: "Oʻchirilgan",
    Completed: "Bajarilgan",
    Resolved: "Hal qilingan",
    Reserved: "Band Qilingan",
    product: "mahsulot",
    service: "xizmat",
    "Cashdesk ID": "Kassa ID",
    Approved: "Tasdiqlangan",
    "In Progress": "Jarayonda",
    InStock: "Sotuvda mavjud",
    SubTotal: "Oraliq miqdor",
    Debited: "Debet qilingan",
    Received: "Qabul qilindi",
    ECash: "Elektron naqd pul",
    Refund: "To'lovni qaytarish",
    "Product Type": "Mahsulot turi",
    "Not Assigned": "Tayinlanmagan",
    "Not assigned": "Tayinlanmagan",
    "Nothing found": "Hech nima topilmadi",
    "Not Found": "Topilmadi",
    "Add a new product": "Yangi mahsulot qo'shish",
    "Update Package Code": "Qadoq kodlarini yangilash",
    Assigned: "Tayinlangan",
    Warehouse: "Ombor",
    Withdrawals: "Chiqimlar",
    Arrivals: "Kirimlar",
    Transfers: "O'tkazmalar",
    "Add Arrival": "Kirim yaratish",
    "Add Withdrawal": "Maxsulot olib tashlash",
    Transfer: "Transfer",
    "Liquidation Number": "Chiqim raqami",
    "New Update": "Yangi yangilanish",
    "New Row": "Yangi qator",
    "Phone Number": "Telefon raqami",
    "Add new phone number": "Yangi telefon raqamini qo'shish",
    "Edit phone number": "Telefon raqamini tahrirlash",
    "Not Approved": "Tasdiqlanmagan",
    "Updated At": "Yangilangan sana",
    Expired: "Muddati tugagan",
    "Total Quantity": "Umumiy miqdor",
    "Not Saved Yet": "Saqlanmadi",
    "Not saved yet": "Saqlanmadi",
    "Owner Search": "Egasini qidirish",
    "Dealer Search": "Dilerni qidirish",
    "Manager Search": "Menejer qidiruvi",
    Expires: "Muddati tugaydigan sana",
    "Manual Discount": "Qo'lda chegirma",
    "Approved Date": "Tasdiqlangan sana",
    "Responsible Person": "Mas'ul shaxs",
    "Page Dashboard": "Boshqaruv paneli",
    "Purchase Price": "Sotib olish narxi",
    "Overall Discount": "Umumiy chegirma",
    "Product Quantity": "Mahsulot miqdori",
    "Approved By": "Tomonidan tasdiqlangan",
    "Last Cheque Number": "Oxirgi chek raqami",
    "First Cheque Number": "Birinchi chek raqami",
    "Create New Password": "Yangi parol yaratish",
    "Create To Continue": "Davom etish uchun yarating",
    Continue: "Davom etish",
    "Responsible Person Search": "Mas'ul shaxsni qidirish",
    "I don't have a Fiscal Module": "Menda Fiskal modul yo'q",
    "The entered passwords do not match": "Kiritilgan parollar mos kelmadi",

    "Are you sure?": "Ishonchingiz komilmi?",
    "Are you sure you want to permanently delete this product?":
        "Haqiqatan ham bu mahsulotni butunlay oʻchirib tashlamoqchimisiz?",
    "Are you sure you want to permanently delete this picture?":
        "Haqiqatan ham bu rasmni butunlay oʻchirib tashlamoqchimisiz?",
    "Are you sure you want to permanently delete this user?":
        "Haqiqatan ham bu foydalanuvchini butunlay oʻchirib tashlamoqchimisiz?",
    "Are you sure you want to permanently delete this fiscal module?":
        "Haqiqatan ham bu fiskal modulni butunlay oʻchirib tashlamoqchimisiz?",
    "Are you sure you want to permanently delete this client?":
        "Haqiqatan ham bu klientni butunlay oʻchirib tashlamoqchimisiz?",
    "Are you sure you want to permanently delete this business?":
        "Haqiqatan ham bu biznesni butunlay oʻchirib tashlamoqchimisiz?",
    "Are you sure you want to permanently delete this branch?":
        "Haqiqatan ham bu filialni butunlay oʻchirib tashlamoqchimisiz?",
    "Are you sure you want to permanently delete this cashdesk?":
        "Haqiqatan ham bu kassani butunlay oʻchirib tashlamoqchimisiz?",
    "Are you sure you want to permanently delete this unit?":
        "Haqiqatan ham bu birlikni butunlay oʻchirib tashlamoqchimisiz?",
    "Are you sure you want to permanently delete this report?":
        "Haqiqatan ham bu hisobotni butunlay oʻchirib tashlamoqchimisiz?",
    "Are you sure you want to permanently delete this cheque?":
        "Haqiqatan ham bu chekni butunlay oʻchirib tashlamoqchimisiz?",
    "Are you sure you want to permanently delete this category?":
        "Haqiqatan ham bu toifani butunlay oʻchirib tashlamoqchimisiz?",
    "Are you sure you want to permanently delete this cart?":
        "Haqiqatan ham bu savatni butunlay oʻchirib tashlamoqchimisiz?",

    // -------------------- USER CREATE -> PASSWORD ----------------------------
    "Password change information": "Parolni o'zgartirish ma'lumotlari",
    "For your security, we recommend that you change your password every 3 months at most.":
        "Xavfsizligingiz uchun parolingizni har 3 oyda ko'pi bilan o'zgartirishingizni tavsiya qilamiz.",

    // --------------------NOTIFICATION WORDS--------------------------------------
    "min ago": "daqiqa oldin",

    // PROFILE DROPDOWN
    Theme: "Tema",

    // SIDEBAR
    Information: "Malumot",
    "The Republic of Uzbekistan": "O'zbekiston Respublikasi",

    // FILTERS
    Reset: "Qaytarish",
    Apply: "Ishlatish",
    "Select All": "Hammasini belgilash",
    "Clear All": "Bekor Qilish",
    Clear: "Tozalash",

    Unit: "O'lchov birligi",

    // PRODUCT UNITS
    Kilo: "Kilogramm",
    Gram: "Gramm",
    Ton: "Tonna",
    Centimeter: "Santimetr",
    Meter: "Metr",
    Liter: "Litr",
    Milliliter: "Millilitr",
    "Cubic meter": "Kub metr",
    "Square meter": "Kvadrat metr",
    Piece: "Dona",
    Pack: "Blok",

    // PERMISSIONS
    "Retrieve Users info": "Foydalanuvchilar haqida ma'lumotni olish",
    "Delete User": "Foydalanuvchini o'chirish",
    "Update User Role info": "Foydalanuvchi rolini yangilash haqida ma'lumot",
    "Retrieve Permission info": "Ruxsatnomani olish haqida ma'lumot",
    "Create Log": "Log yaratish",
    "Retrieve IP address": "IP manzilni olish",
    "Create Ticket": "Chipta yaratish",
    "Post Message to Ticket": "Chiptaga xabar yuborish",
    "Retrieve Upload": "Yuklamani olish",
    "Retrieve Notifications": "Bildirishnomalarni olish",
    "Update Fiscal Module": "Soliq modulini yangilash",
    "Create Employee": "Xodim yaratish",
    "Delete Employee": "Xodimni o'chirish",
    "Update Employee Position info":
        "Xodim lavozimini yangilash haqida ma'lumot",
    "Retrieve Page": "Sahifani olish",
    "Delete Client": "Mijozni o'chirish",
    "Update Business": "Biznesni yangilash",
    "Retrieve Business Branch": "Biznes filialini olish",
    "Create Cashdesks": "Kassalarni yaratish",
    "Delete Cashdesks": "Kassalarni o'chirish",
    "Update Users info": "Foydalanuvchilar ma'lumotini yangilash",
    "Create User Role": "Foydalanuvchi rolini yaratish",
    "Delete User Role": "Foydalanuvchi rolini o'chirish",
    "Update Permission info": "Ruxsatnomani yangilash haqida ma'lumot",
    "Retrieve Log info": "Log ma'lumotini olish",
    "Update IP address": "IP manzilini yangilash",
    "Retrieve Ticket": "Chiptani olish",
    "Post Comment to Ticket": "Chiptaga izoh yuborish",
    "Update Upload": "Yuklamani yangilash",
    "Retrieve Tasnif Product": "Tasnif mahsulotini olish",
    "Retrieve Employee info": "Xodim haqida ma'lumotni olish",
    "Create Employee Position": "Xodim lavozimini yaratish",
    "Delete Employee Position": "Xodim lavozimini o'chirish",
    "Update Page": "Sahifani yangilash",
    "Retrieve Client info": "Mijoz haqida ma'lumotni olish",
    "Client's Passport Scan": "Mijozning pasport nusxasi",
    "Delete Business": "Biznesni o'chirish",
    "Update Business Branch": "Biznes filialini yangilash",
    "Retrieve Cashdesks": "Kassalarni olish",
    "Retrieve User Role": "Foydalanuvchi rolini olish",
    "Create Permission": "Ruxsatnomani yaratish",
    "Delete Permission": "Ruxsatnomani o'chirish",
    "Create IP address": "IP manzilini yaratish",
    "Delete IP address": "IP manzilini o'chirish",
    "Update Ticket": "Chiptani yangilash",
    "Create Uploads": "Yuklamalarni yaratish",
    "Create Notifications": "Bildirishnomalarni yaratish",
    "Retrieve Fiscal Module": "Soliq modulini olish",
    "Update Tasnif Product": "Tasnif mahsulotini yangilash",
    "Update Employee info": "Xodim ma'lumotini yangilash",
    "Retrieve Employee Position info": "Xodim lavozimini olish haqida ma'lumot",
    "Create Page": "Sahifani yaratish",
    "Delete Page": "Sahifani o'chirish",
    "Update Client info": "Mijoz ma'lumotini yangilash",
    "Retrieve Business": "Biznesni olish",
    "Create Business Branch": "Biznes filialini yaratish",
    "Delete Business Branch": "Biznes filialini o'chirish",
    "Update Cashdesks": "Kassalarni yangilash",
    "Create Cart": "Savat yaratish",
    "Update Cart": "Savatni yangilash",
    "Retrieve Cheques": "Cheklarni olish",
    "Retrieve Reports": "Hisobotlarni olish",
    "Delete Unit": "Birlikni o'chirish",
    "Update Product": "Mahsulotni yangilash",
    "Retrieve Product Category": "Mahsulot kategoriyasini olish",
    "Delete Client Permission": "Mijoz ruxsatnomasini o'chirish",
    "Create Client Role": "Mijoz rolini yaratish",
    "Update Client Role": "Mijoz rolini yangilash",
    "Delete Cart": "Savatni o'chirish",
    "Create Cheque": "Chek yaratish",
    "Update Cheque": "Chekni yangilash",
    "Update Report": "Hisobotni yangilash",
    "Retrieve Unit": "Birlikni olish",
    "Delete Product": "Mahsulotni o'chirish",
    "Create Product Category": "Mahsulot kategoriyasini yaratish",
    "Update Product Category": "Mahsulot kategoriyasini yangilash",
    "Retrieve Client Permission": "Mijoz ruxsatnomasini olish",
    "Delete Client Role": "Mijoz rolini o'chirish",
    "Initialize Cashdesk Application": "Kassa ilovasini ishga tushirish",
    "Retrieve Cart": "Savatni olish",
    "Delete Cheque": "Chekni o'chirish",
    "Create Report": "Hisobot yaratish",
    "Create Unit": "Birlik yaratish",
    "Update Unit": "Birlikni yangilash",
    "Retrieve Product": "Mahsulotni olish",
    "Delete Product Category": "Mahsulot kategoriyasini o'chirish",
    "Create Client Permission": "Mijoz ruxsatnomasini yaratish",
    "Update Client Permission": "Mijoz ruxsatnomasini yangilash",
    "Retrieve Client Roles": "Mijoz rollarini olish",

    // TOASTS
    "Invoice successfully created": "Faktura muvaffaqiyatli yaratildi",
    "Business validation failed: businessType: Business type is required": "Biznesni tekshirish amalga oshmadi: businessType: biznes turi talab qilinadi",
    "The provided phone number is invalid": "Taqdim etilgan telefon raqami yaroqsiz",
    "It looks like you don't have access to this request. Please contact support.": "Sizda bu so'rovga kirish imkoni yo'qdek. Qo'llab-quvvatlash murojaat qiling.",
    "Password should be at least 8 characters long.": "Parol kamida 8 belgidan iborat bo'lishi kerak.",
    "Password should contain at least one alphabetic character.": "Parol kamida bitta alifbo belgisini o'z ichiga olishi kerak.",
    "Password should contain at least one numeric character.": "Parol kamida bitta raqamli belgidan iborat bo'lishi kerak.",
    "Some error occurred": "Qandaydir xatolik yuz berdi",
    "User successfully updated": "Foydalanuvchi muvaffaqiyatli yangilandi",
    "User successfully created": "Foydalanuvchi muvaffaqiyatli yaratildi",
    "Successfully updated": "Muvaffaqiyatli yangilandi",
    "Successfully created": "Muvaffaqiyatli yaratildi",
    "File was successfully uploaded": "Fayl muvaffaqiyatli yuklandi",
    "File was partially uploaded": "Fayl qisman yuklandi",
    "An error occurred importing products. ":
        "Mahsulotlarni yuklashda xatolik yuz berdi. ",
    "Something went wrong. ": "Nimadir xato ketdi. ",
    "Successfully deleted": "Muvaffaqiyatli oʻchirildi",
    "Please, choose a  unit": "Iltimos, o'lchov birligini tanlang",
    "Please check some product to delete":
        "O'chirmoqchi bo'lgan mahsulotni tanlang",
    "Please check some category to delete":
        "O'chirmoqchi bo'lgan kategoriyani tanlang",
    "Please, choose a product from Tasnif Database":
        "Tasnif ma'lumotlar bazasidan mahsulotni tanlang",
    "You should first create a category": "Birinchidan, kategoriya yarating",
    "Please, choose Start Date and End Date of discount":
        "Chegirmaning boshlanish va tugash sanasini tanlang",
    "Please, choose at least one category": "Kamida bitta toifani tanlang",
    "Code sent to your phone number": "Kodni telefoningizga yubordik",
    "New password was successfully applied":
        "Yangi parol muvaffaqiyatli kiritildi",
    "Delete your file and try again":
        "Faylni o'chirib tashlang va qaytadan urinib ko'ring",
    "You did not provide a proper fiscal module":
        "Siz noto'g'ri fiskal modul taqdim etdingiz",
    "You are required to upload an image here. Available formats: .jpg, .png, .webp, .svg":
        "Bu yerga rasm yuklashingiz kerak. Mavjud formatlar: .jpg, .png, .webp, .svg",
    "User or Client does not exist": "Bu raqamga ega foydalanuvchi yo'q",
    "Select an application type": "Ilova turini tanlang",
    "Select the type of business": "Biznes turini tanlang",
    "Multiple files are not allowed!":
        "Siz faqat bitta faylni yuklashingiz mumkin",
    "You pasted wrong OTP format":
        "Siz noto'g'ri OTP formatini joylashtirdingiz",
    "Export to PDF": "PDF formatiga eksport qilish",
    Versions: "Versiya",
};
