import { Box, colors, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import { translate } from "../../translations/translate";
import { ApiService } from "../../utils/ApiService";
import { errorToast } from "../../utils/toast";
import Modal from "react-modal";
import axios from "axios";
import useApiHook from "../../hooks/useApiHook";
import { updateTotalAction } from "../../redux/reducers/paginationControllerReducer";
import { getParsedDate } from "../../utils/getParsedDate";
import { Link } from "react-router-dom";
import UserLinkCard from "../UserLinkCard";
import StatusTag from "../StatusTag";
import Preloader from "../Preloader";
import BreadCrumbs from "../BreadCrumbs";
import Filter from "../Filter";
import { DataGrid } from "@mui/x-data-grid";
import ModalWindow from "../ModalWindow";
import Button from "../global/Button";
import config, { getLocaleText } from "../../config";
import avatar from "../../assets/images/avatar.png";

const ZReportChequeTable = ({ hideBreadcrumbs = false }) => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const navigate = useNavigate();
    const [id] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const { state } = useLocation();
    const params = useParams();
    const businessID = params.id;
    const [exportModalOpen, setExportModalOpen] = useState(false);
    const lang = useSelector((state) => state.languageReducer.lang);
    const { token } = useSelector((state) => state.authReducer);
    const __ = (key) => translate(lang, key);
    const reportID = state?.reportID || params?.reportID;

    const closeModal = () => {
        setModalIsOpen(false);
    };

    let urlBase = `${config.API_BASE_URL}/businesses/${businessID}/reports/${reportID}/cheques`;
    const [url, setUrl] = useState(urlBase);
    const dispatch = useDispatch();
    const controller = useSelector(
        (state) => state.paginationControllerReducer,
    );

    const removeCheque = () => {
        ApiService.deleteEvent(`/businesses/${businessID}/cheques/${id}`, token)
            .then(response => {
                if (response.status === 200) {
                    window.location.reload();
                }
            })
            .catch(error => {
                errorToast(error);
            });
    };
    const closeExportModal = () => {
        setExportModalOpen(false);
    };

    const exportCheques = () => {
        setIsLoading(true);
        axios.get(`${config.API_BASE_URL}/businesses/${state?.business?._id || businessID}/cheques/export`, {
            headers: {
                "Authorization": `Bearer ${token}`,
            }
        })
            .then(response => {
                setIsLoading(false);
                if (response.status === 200) {
                    let downloadLink = document.createElement("a");
                    downloadLink.href = `${config.API_HOST}/${response.data.file}`;
                    downloadLink.download = `receipts-${businessID}.xlsx`;
                    document.body.appendChild(downloadLink);
                    downloadLink.click();
                    document.body.removeChild(downloadLink);
                }
            });
    };

    useEffect(() => {
        setUrl(
            `${urlBase}?&page=${controller.page + 1}&limit=${controller.pageSize
            }`,
        );
    }, [controller.page, controller.pageSize]);

    const { data } = useApiHook(url);
    const receiptsList = data?.data;

    useEffect(() => {
        if (data) {
            dispatch(updateTotalAction(parseInt(data?.data?.totalDocs) || 0));
        }
    }, [data]);

    let receipts = [];

    if (receiptsList) {
        receiptsList.map((receipt) => {
            let receiptSingle = {
                id: receipt._id,
                receiptNumber: receipt.chequeNumber,
                receiptFrom: receipt.chequeFrom,
                status: receipt.status,
                cashdeskID: receipt.cashdeskID?._id,
                cashdeskUniqueID: receipt.cashdeskID?.uniqueID,
                branchID: receipt.cashdeskID?.branchID?._id,
                branchSlug: receipt.cashdeskID?.branchID?.slug,
                branchName: receipt.cashdeskID?.branchID?.name,
                businessID: receipt.businessID?._id,
                businessSlug: receipt.businessID?.slug,
                businessName: receipt.businessID?.name,
                ownerID: receipt.owner?._id,
                ownerName: receipt.owner?.name
                    ? receipt.owner?.name?.first +
                    " " +
                    receipt.owner?.name?.last
                    : "-",
                ownerSlug: receipt.owner?.slug,
                ownerAvatar: receipt.owner?.avatar?.url,
                totalAmount: receipt.received?.total,
                createdAt: getParsedDate(receipt.dates?.createdAt, true),
                shiftNumber: receipt.shiftNumber,
                reportID: receipt.reportID,
            };
            receipts.push(receiptSingle);
        });
    }

    const columns = [
        {
            field: "receiptNumber",
            headerName: __("Receipt Number"),
            flex: 1,
            cellClassName: "mane-column--cell",
            renderCell: ({ row }) => {
                return  (
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        gap="5px"
                        onClick={() =>
                        navigate(
                            `/businesses/${businessID}/receipts/${row.id}`,
                            { state: { receipt: { _id: row.id } } }
                            )
                        }
                    >
                        <Typography
                            fontFamily="Manrope, sans-serif"
                            fontWeight={600}
                            fontSize={14}
                            color={colors.common["black"]}
                        >
                            {"#" + row.receiptNumber}
                        </Typography>
                        {row.receiptFrom && row.status === "refund" && (
                            <Box display="flex" alignItems="center" gap="5px">
                                <i className="icon-reload-update"></i>
                                <Typography
                                    fontFamily="Manrope, sans-serif"
                                    fontWeight={600}
                                    fontSize={14}
                                    color={colors.common["black"]}
                                >
                                    {"#" + row.receiptFrom}
                                </Typography>
                            </Box>
                        )}
                    </Box>
                );
            },
        },
        {
            field: "shift",
            headerName: __("Shift"),
            flex: 1,
            renderCell: ({ row: { shiftNumber } }) => {
                return (
                    <Box display="flex" justifyContent="center">
                        <Typography
                            fontFamily="Manrope, sans-serif"
                            fontWeight={400}
                            fontSize={14}
                            color={colors.common["black"]}
                        >
                            {shiftNumber || "—"}
                        </Typography>
                    </Box>
                );
            },
        },
        {
            field: "ownerName",
            headerName: __("Cashier"),
            type: "string",
            flex: 1.9,
            renderCell: ({ row }) => {
                return (
                    <Box display="flex" justifyContent="center">
                        <UserLinkCard
                            onClick={() => {
                                navigate(
                                    `/clients/${row.ownerSlug || row.ownerID}`,
                                );
                            }}
                            avatar={avatar}
                            name={row.ownerName}
                        />
                    </Box>
                );
            },
        },
        {
            field: "createdAt",
            headerName: __("Created At"),
            flex: 1.5,
            renderCell: ({ row: { createdAt } }) => {
                return (
                    <Box display="flex" justifyContent="center">
                        <Typography
                            fontFamily="Manrope, sans-serif"
                            fontWeight={400}
                            fontSize={14}
                            color={colors.grey[500]}
                        >
                            {createdAt || "—"}
                        </Typography>
                    </Box>
                );
            },
        },
        {
            field: "branch",
            headerName: __("Branch"),
            flex: 1.5,
            renderCell: ({ row }) => {
                return row.branchID && row.branchSlug ? (
                    <Box display="flex" justifyContent="center">
                        <Link to={`/branches/${row.branchSlug}`}>
                            <Typography
                                fontFamily="Manrope, sans-serif"
                                fontWeight={400}
                                fontSize={14}
                                color={colors.common["black"]}
                            >
                                {row.branchName || "—"}
                            </Typography>
                        </Link>
                    </Box>
                ) : (
                    <Box display="flex" justifyContent="center">
                        <Typography
                            fontFamily="Manrope, sans-serif"
                            fontWeight={400}
                            fontSize={14}
                            color={colors.common["black"]}
                        >
                            {row.branchName || "—"}
                        </Typography>
                    </Box>
                );
            },
        },
        {
            field: "cashdesk",
            headerName: __("Cashdesk"),
            flex: 1.5,
            renderCell: ({ row }) => {
                return row.cashdeskID && row.cashdeskUniqueID ? (
                    <Box display="flex" justifyContent="center">
                        <Link to={`/cashdesks/${row.cashdeskUniqueID}`}>
                            <Typography
                                fontFamily="Manrope, sans-serif"
                                fontWeight={400}
                                fontSize={14}
                                color={colors.common["black"]}
                            >
                                {row.cashdeskUniqueID || "—"}
                            </Typography>
                        </Link>
                    </Box>
                ) : (
                    <Box display="flex" justifyContent="center">
                        <Typography
                            fontFamily="Manrope, sans-serif"
                            fontWeight={400}
                            fontSize={14}
                            color={colors.common["black"]}
                        >
                            {row.cashdeskUniqueID || "—"}
                        </Typography>
                    </Box>
                );
            },
        },
        {
            field: "status",
            headerName: __("Type"),
            type: "string",
            flex: 1.3,
            renderCell: (props) => {
                return (
                    <Box display="flex" justifyContent="center">
                        <StatusTag
                            className={`ui-status sm ${props.value === "blocked"
                                ? "question"
                                : "active"
                                }`}
                            text={props.value}
                        />
                    </Box>
                );
            },
        },
    ];
    // FILTER BUTTONS
    const buttons = [
        {
            text: __("Export Receipts"),
            onClick: () => {
                setExportModalOpen(true);
            },
        },
    ];

    if (isLoading) return <Preloader />;

    return (
        <div>
        {!hideBreadcrumbs && (
            <BreadCrumbs
              breadcrumbs={[
                  { label: "Businesses", link: "/businesses" },
                  { label: "Receipts" },
              ]}
            />
            )}
            <div className="page page-cheque-table">
                <Box>
                    {/* set the text prop to the filter component */}
                    <Filter description={__("Receipts")} buttons={buttons} />
                    <Box
                      sx={{
                          "&": {
                              minHeight: "350px",
                          },
                          "& .name-column--cell": {
                              color: colors.green[300],
                          },
                          "& .MuiDataGrid-footerContainer": {
                              borderTop: "none",
                          },
                      }}
                    >
                        <DataGrid
                          rows={receipts}
                          columns={columns}
                          rowCount={controller.total}
                          page={controller.page}
                          pageSize={controller.pageSize}
                          {...config.TABLE_CONFIG(__)}
                          localeText={getLocaleText(__)}
                        />
                    </Box>
                </Box>
                <Modal
                  className="delete-modal"
                  isOpen={modalIsOpen}
                  onRequestClose={closeModal}
                >
                    <div className="modal-bottom">
                        <ModalWindow
                          title={__("Are you sure?")}
                          text={__(
                            "Are you sure you want to permanently delete this cheque?",
                          )}
                          onClick={closeModal}
                          onClickDelete={removeCheque}
                        />
                    </div>
                </Modal>
                <Modal
                  className="delete-modal"
                  isOpen={exportModalOpen}
                  onRequestClose={closeExportModal}
                >
                    <div className="modal-bottom">
                        <div className="component-modal-window">
                            <div className="modal-inner">
                                <div className="modal-title">
                                    <h1 className="modal-window-title">
                                        {__("Export of the Cheques")}
                                    </h1>
                                </div>
                                <div
                                className="close-icon"
                                onClick={() => {
                                    setExportModalOpen(false);
                                }}
                                ></div>
                                <div className="modal-body">
                                    <div className="window-modal-text">
                                        <p className="window-text">
                                            {__(
                                            "Are you sure you want to export your cheques into Excel file?",
                                            )}
                                        </p>
                                    </div>
                                </div>
                                <div className="btn-container">
                                    <Button
                                    className="btn outline sm"
                                    text={__("Cancel")}
                                    onClick={() =>
                                        setExportModalOpen(false)
                                    }
                                    ></Button>
                                    <Button
                                    className="btn sm"
                                    text={__("Continue")}
                                    onClick={() => {
                                        setExportModalOpen(false);
                                        exportCheques();
                                    }}
                                    ></Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        </div>
    );
};

export default ZReportChequeTable;
