import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { Box, colors, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { translate } from "../../../translations/translate";
import { useSelector, useDispatch } from "react-redux";
import { errorToast } from "../../../utils/toast";
import { ApiService } from "../../../utils/ApiService";
import useApiHook from "../../../hooks/useApiHook";
import Preloader from "../../../components/Preloader";
import StatusTag from "../../../components/StatusTag";
import Action from "../../../components/Action/index.jsx";
import ModalWindow from "../../../components/ModalWindow";
import Filter from "../../../components/Filter/index.jsx";
import BreadCrumbs from "../../../components/BreadCrumbs/index.jsx";
import config from "../../../config";
import { updateTotalAction } from "../../../redux/reducers/paginationControllerReducer";
import "../../../assets/fonts/epos-icons/style.css";
import "./styles.sass";

const PageTable = () => {

    // STATE
    const state = useLocation();

    const dispatch = useDispatch();
    const filters = {
        isRole: true,
        isStatus: true,
    };
    const page = "users";
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const navigate = useNavigate();
    const [id, setId] = useState();
    const [searchInputValue, setSearchInputValue] = useState("");

    let urlBase = `${config.API_BASE_URL}/users?`;
    const [url, setUrl] = useState(urlBase);

    const openModals = (id) => {
        setModalIsOpen(true);
        setId(id);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };
    if(state?.state) {

        // ROLE FILTER
        let role = "";
        state?.state?.role?.forEach(singleRole => {
            if(singleRole.isChecked) {
                role += `&roles[]=${singleRole.id}`;
            }
        });

        // STATUS FILTER
        let status = "";
        state?.state?.status?.forEach(singleStatus => {
            if(singleStatus.isChecked) {
                status += `&${singleStatus.name.toLowerCase()}=true`;
            }
        });

        // CONCATENATING URL WITH FILTERS
        urlBase += role + status;
    }
    const { data, loading, error } = useApiHook(url);
    const { token } = useSelector((state) => state.authReducer);
    const controller = useSelector(
        (state) => state.paginationControllerReducer,
    );

    useEffect(() => {
        if(searchInputValue)
            setUrl(`${config.API_BASE_URL}/users/search/user?query=${searchInputValue}`);
        else setUrl(urlBase);
    }, [searchInputValue]);

    useEffect(() => {
        if (data) {
            dispatch(updateTotalAction(parseInt(data?.data?.totalDocs) || 0));
            // dispatch(updateIsLoadingAction(false));  // NO NEED FOR IT BUT IN OLD VERSION IT WAS SO
        }
    }, [data]);

    useEffect(() => {
        setUrl(
            `${urlBase}&page=${controller.page + 1}&limit=${
                controller.pageSize
            }`,
        );
    }, [controller.page, controller.pageSize]);

    const lang = useSelector((state) => state.languageReducer.lang);
    const __ = (key) => translate(lang, key);
    const userList = data?.data?.docs.map((item) => ({
        firstName: item?.name?.first || "—",
        lastName: item?.name?.last || "—",
        id: item?._id,
        role: __(item?.role?.title) || "—",
        email: item?.email || "—",
        phone: item?.phone || "—",
        status: item?.status,
    }));

    if (loading)
        return (
            <div>
                <Preloader />
            </div>
        );

    if (error) return <div>{error}</div>;

    const removeUser = () => {
        ApiService.deleteEvent(`/users/${id}`, token)
            .then((res) => {
                if (res.status === 200) {
                    window.location.reload();
                }
            })
            .catch((error) => {
                errorToast(error);
            });
    };

    const columns = [
        {
            field: "name",
            headerName: __("Name"),
            flex: 1,
            cellClassName: "mane-column--cell",
            renderCell: ({ row }) => {
                return (
                    <Box
                        display="flex"
                        justifyContent="flex-start"
                        width={"100%"}
                    >
                        <Link
                            to={`/users/${row.id}`}
                            state={{
                                user: {
                                    id: row.id,
                                },
                            }}
                            className="link"
                        >
                            <Typography
                                fontFamily="Manrope, sans-serif"
                                fontWeight={600}
                                fontSize={14}
                                color={colors.common["black"]}
                                textOverflow={"ellipsis"}
                                overflow={"hidden"}
                                whiteSpace={"nowrap"}
                            >
                                {row.firstName + " " + row.lastName}
                            </Typography>
                        </Link>
                    </Box>
                );
            },
        },
        {
            field: "role",
            headerName: __("Role"),
            flex: 1,
            renderCell: ({ row: { role } }) => {
                return (
                    <Box display="flex" justifyContent="center">
                        <Typography
                            fontFamily="Manrope, sans-serif"
                            fontWeight={400}
                            fontSize={14}
                            color={colors.common["black"]}
                        >
                            {role}
                        </Typography>
                    </Box>
                );
            },
        },
        {
            field: "email",
            headerName: __("Email"),
            flex: 1,
            renderCell: ({ row: { email } }) => {
                return (
                    <Box
                        display="flex"
                        justifyContent="flex-start"
                        width={"100%"}
                    >
                        <Typography
                            fontFamily="Manrope, sans-serif"
                            fontWeight={400}
                            fontSize={14}
                            color={colors.common["black"]}
                            textOverflow={"ellipsis"}
                            overflow={"hidden"}
                            whiteSpace={"nowrap"}
                        >
                            {email}
                        </Typography>
                    </Box>
                );
            },
        },
        {
            field: "phone",
            headerName: __("Phone Number"),
            className: "table-phone",
            flex: 1,
            renderCell: ({ row: { phone } }) => {
                return (
                    <Box
                        display="flex"
                        justifyContent="flex-start"
                        width={"100%"}
                    >
                        <Typography
                            fontFamily="Manrope, sans-serif"
                            fontWeight={400}
                            fontSize={14}
                            color={colors.grey[500]}
                            textOverflow={"ellipsis"}
                            overflow={"hidden"}
                            whiteSpace={"nowrap"}
                        >
                            {phone}
                        </Typography>
                    </Box>
                );
            },
        },
        {
            field: "status",
            headerName: __("Status"),
            type: "string",
            flex: 1,
            renderCell: (props) => {
                return (
                    <Box display="flex" justifyContent="center">
                        <StatusTag className={" sm "} text={props.value} />
                    </Box>
                );
            },
        },
        {
            field: "action",
            headerName: __("Action"),
            type: "string",
            renderCell: ({ row }) => {
                return (
                    <Box display="flex" justifyContent="center">
                        <Action
                            onClick={() =>
                                navigate(`/users/${row.slug || row.id}/edit`, {
                                    state: {
                                        user: {
                                            id: row.id,
                                            slug: row.slug,
                                        },
                                    },
                                })
                            }
                            onClickView={() =>
                                navigate(`/users/${row.slug || row.id}`, {
                                    state: {
                                        user: {
                                            id: row.id,
                                            slug: row.slug,
                                        },
                                    },
                                })
                            }
                            openModal={() => openModals(row?.id)}
                        />
                    </Box>
                );
            },
        },
    ];

    return (
        <div>
            <BreadCrumbs breadcrumbs={[{ label: "Users", link: "/users" }]} />
            <div className="page page-table">
                <Box>
                    <Filter
                        description={__("Users")}
                        createLink="/users/create"
                        text={__("Add User")}
                        filters={filters}
                        page={page}
                        search={searchInputValue}
                        setSearch={setSearchInputValue}
                    />
                    <Box
                        sx={{
                            "&": {
                                margin: "0",
                                height: "90vh",
                                minHeight: "350px",
                            },
                            "& .name-column--cell": {
                                color: colors.green[300],
                            },
                            "& .MuiDataGrid-footerContainer": {
                                borderTop: "none",
                            },
                        }}
                    >
                        <DataGrid
                            rows={userList}
                            columns={columns}
                            rowCount={controller.total}
                            page={controller.page}
                            pageSize={controller.pageSize}
                            {...config.TABLE_CONFIG(__)}
                        />
                    </Box>
                </Box>
                <Modal
                    className="delete-modal"
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                >
                    <div className="modal-bottom">
                        <ModalWindow
                            title={__("Are you sure?")}
                            text={__(
                                "Are you sure you want to permanently delete this user?",
                            )}
                            onClick={closeModal}
                            onClickDelete={removeUser}
                        />
                    </div>
                </Modal>
            </div>
        </div>
    );
};

export default PageTable;
