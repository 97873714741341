import React, { useEffect, useState } from "react";
import { Box, colors, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { ApiService } from "../../../utils/ApiService";
import { useDispatch, useSelector } from "react-redux";
import { translate } from "../../../translations/translate";
import { errorToast } from "../../../utils/toast";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { updateTotalAction } from "../../../redux/reducers/paginationControllerReducer";
import StatusTag from "../../../components/StatusTag";
import Modal from "react-modal";
import ModalWindow from "../../../components/ModalWindow";
import Action from "../../../components/Action/index.jsx";
import BreadCrumbs from "../../../components/BreadCrumbs/index.jsx";
import Filter from "../../../components/Filter/index.jsx";
import UserLinkCard from "../../../components/UserLinkCard";
import useApiHook from "../../../hooks/useApiHook";
import config from "../../../config";
import Preloader from "../../../components/Preloader";
import "./styles.sass";
import {getTokenData} from "../../../utils/helper";

const PageBusinessTable = () => {

    const state = useLocation();

    const filters = {
        isBusinessType: true,
        isStatus: true,
    };

    const page = "businesses";

    const [modalIsOpen, setModalIsOpen] = useState(false);
    const navigate = useNavigate();
    const [id, setId] = useState();
    const [searchInputValue, setSearchInputValue] = useState("");

    const token = useSelector((state) => state.authReducer.token);
    const lang = useSelector((state) => state.languageReducer.lang);
    const decodedToken = getTokenData(token);

    const __ = (key) => translate(lang, key);

    const storedUserData = useSelector((state) => state.authReducer);
    const isClient = storedUserData.role === "client";
    const isDealer = decodedToken?.role?.title === "Dealer";
    let url = `${config.API_BASE_URL}/businesses?`;
    const [urlBase, setUrlBase] = useState(url);

    if(state?.state) {

        let status = "";
        state?.state?.status?.forEach(singleStatus => {
            if(singleStatus.isChecked) {
                status += `&${singleStatus.name.toLowerCase()}=true`;
            }
        });

        let businessType = "";
        if(state?.state?.businessType?.length) {
            businessType += `&businessType=${state?.state?.businessType[0]}`;
        }

        url += status + businessType;
    }
    const { data, loading, error } = useApiHook(urlBase);

    const businesses = data?.data?.docs;
    const dispatch = useDispatch();
    const controller = useSelector(
        (state) => state.paginationControllerReducer,
    );

    useEffect(() => {
        if(searchInputValue)
            setUrlBase(`${config.API_BASE_URL}/businesses/search/business?query=${searchInputValue}${isDealer ? `&dealerID=${decodedToken?.ID}` : ""}`);
        else setUrlBase(url);
    }, [searchInputValue]);

    useEffect(() => {
        if (data) {
            dispatch(updateTotalAction(parseInt(data?.data?.totalDocs) || 0));
        }
    }, [data]);

    useEffect(() => {
        setUrlBase(
            `${url}&page=${controller.page + 1}&limit=${
                controller.pageSize
            }`,
        );
    }, [controller.page, controller.pageSize]);

    if (loading)
        return (
            <div>
                <Preloader />
            </div>
        );
    if (error) return <div>{error}</div>;

    let businessList = [];

    businesses?.map((item) => {
        let businessSingle = {
            businessName: item.name || "—",
            businessSlug: item.slug ,
            businessType: item.businessType || "—",
            id: item._id,
            role: item?.role?.title || "—",
            email: item.email || "—",
            phone: item.phone || "—",
            status: item.status,
            ownerName:
                item.owner?.displayName?.first ||
                item.owner?.name?.first ||
                "—",
            ownerLastName:
                item.owner?.displayName?.last || item.owner?.name?.last || "—",
            ownerAvatar: item.owner?.avatar ? item.owner?.avatar?.fileInfo?.url : "",
            tin: item?.tin
        };
        businessList.push(businessSingle);
    });

    const removeBusiness = () => {
        ApiService.deleteEvent(`/businesses/${id}`, token)
            .then((res) => {
                if (res.status === 200) {
                    window.location.reload();
                }
            })
            .catch((error) => {
                errorToast(error);
            });
    };

    const openModals = (id) => {
        setModalIsOpen(true);
        setId(id);
    };
    const closeModal = () => {
        setModalIsOpen(false);
    };

    const columns = [
        {
            field: "businessName",
            headerName: __("Name"),
            flex: 2.2,
            cellClassName: "mane-column--cell",
            renderCell: ({ row, row: { businessName } }) => {
                return (
                    <Box
                        display="flex"
                        justifyContent="flex-start"
                        width={"100%"}
                    >
                        <Link
                            to={`/businesses/${row.id}`}
                            state={{
                                business: {
                                    id: row.id,
                                },
                            }}
                            className="link"
                        >
                            <Typography
                                fontFamily="Manrope, sans-serif"
                                fontWeight={600}
                                fontSize={14}
                                color={colors.common["black"]}
                                textOverflow={"ellipsis"}
                                overflow={"hidden"}
                                whiteSpace={"nowrap"}
                            >
                                {businessName}
                            </Typography>
                        </Link>
                    </Box>
                );
            },
        },
        {
            field: "businessType",
            headerName: __("Type"),
            renderCell: ({ row: { businessType } }) => {
                return (
                    <Box display="flex" justifyContent="center">
                        <Typography
                            fontFamily="Manrope, sans-serif"
                            fontWeight={400}
                            fontSize={14}
                            color={colors.common["black"]}
                        >
                            {__(businessType)}
                        </Typography>
                    </Box>
                );
            },
        },
        {
            field: "number",
            headerName: __("Phone"),
            flex: 2,
            renderCell: ({ row: { phone } }) => {
                return (
                    <Box
                        display="flex"
                        justifyContent="flex-start"
                        width={"100%"}
                    >
                        <Typography
                            fontFamily="Manrope, sans-serif"
                            fontWeight={400}
                            fontSize={14}
                            color={colors.common["black"]}
                            textOverflow={"ellipsis"}
                            overflow={"hidden"}
                            whiteSpace={"nowrap"}
                        >
                            {phone}
                        </Typography>
                    </Box>
                );
            },
        },
        {
            field: "ownerName",
            headerName: __("Owner"),
            type: "string",
            flex: 2,
            renderCell: ({
                row: { ownerName, ownerLastName, ownerAvatar },
            }) => {
                return (
                    <Box display="flex" justifyContent="center">
                        <UserLinkCard
                            userLink="../"
                            avatar={ownerAvatar}
                            name={ownerName + " " + ownerLastName}
                        />
                    </Box>
                );
            },
        },
        {
            field: "tin",
            headerName: __("TIN/PINFL"),
            type: "string",
            flex: 2,
            renderCell: ({
                row: {
                    tin
                },
            }) => {
                return (
                    <Box display="flex" justifyContent="center">
                        <Typography
                          fontFamily="Manrope, sans-serif"
                          fontWeight={400}
                          fontSize={14}
                          color={colors.common["black"]}
                        >
                            {tin}
                        </Typography>
                    </Box>
                );
            },
        },
        {
            field: "status",
            headerName: __("Status"),
            type: "string",
            flex: 1.5,
            renderCell: (props) => {
                return (
                    <Box display="flex" justifyContent="center">
                        <StatusTag className={"sm"} text={props.value} />
                    </Box>
                );
            },
        },
        {
            field: "action",
            headerName: __("Action"),
            type: "string",
            renderCell: ({ row }) => {
                return (
                    <Box display="flex" justifyContent="center">
                        <Action
                            onClick={() =>
                                navigate(
                                    `/businesses/${row.slug || row.id}/edit`,
                                    {
                                        state: {
                                            business: {
                                                id: row.id,
                                                slug: row.slug,
                                            },
                                        },
                                    },
                                )
                            }
                            onClickView={() =>
                                navigate(`/businesses/${row.slug || row.id}`, {
                                    state: {
                                        business: {
                                            id: row.id,
                                            slug: row.slug,
                                        },
                                    },
                                })
                            }
                            openModal={() => openModals(row?.id)}
                        />
                    </Box>
                );
            },
        },
    ];

    return (
        <div>
            <BreadCrumbs
                breadcrumbs={[{ label: "Businesses", item: "/businesses" }]}
            />
            <div
                className={`page page-business-table ${isClient ? "grid" : ""}`}
            >
                <Box>
                    <Filter
                        description={__("Businesses")}
                        text={__("Add Business")}
                        createLink="/businesses/create"
                        filters={filters}
                        page={page}
                        search={searchInputValue}
                        setSearch={setSearchInputValue}
                    />
                    <Box
                        sx={{
                            "&": {
                                margin: "0",
                                width: "100%",
                                height: "90vh",
                                minHeight: "350px",
                            },
                            "& .name-column--cell": {
                                color: colors.green[300],
                            },
                            "& .MuiDataGrid-footerContainer": {
                                borderTop: "none",
                            },
                        }}
                    >
                        <DataGrid
                            rows={businessList}
                            columns={columns}
                            rowCount={controller.total}
                            page={controller.page}
                            pageSize={controller.pageSize}
                            {...config.TABLE_CONFIG(__)}
                        />
                    </Box>
                </Box>
                <Modal
                    className="delete-modal"
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                >
                    <div className="modal-bottom">
                        <ModalWindow
                            title={__("Are you sure?")}
                            text={__(
                                "Are you sure you want to permanently delete this business?",
                            )}
                            onClick={closeModal}
                            onClickDelete={removeBusiness}
                        />
                    </div>
                </Modal>
            </div>
        </div>
    );
};

export default PageBusinessTable;
