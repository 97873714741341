import {capitalize} from "../../utils/capitalize";
import "./styles.sass";

const CustomInput = ({label = "", ...otherProps}) => (
    <label className="custom-input_label">
      <input className="custom-input" {...otherProps} />
      {label && (
        <span className="custom-input_title">{capitalize(label)}</span>
      )}
    </label>
  );

export default CustomInput;
