import jwt_decode from "jwt-decode";

export const parseFiscalID = (fiscalID) => {
    if (!fiscalID) fiscalID = "";
    return fiscalID
        .replace(/(\D)+/g, "")
        ?.match(/.{1,4}/g)
        ?.join(" ");
};

export const parseVirtualID = (fiscalID) => {
    if (!fiscalID) fiscalID = "";
    return fiscalID
        .replace(/(\D)+/g, "")
        ?.match(/.{1,3}/g)
        ?.join(" ");
};

export const getTokenData = (token) => {
    if (token)
        return jwt_decode(token);
};

export const combineArrays = (arr1, arr2) => {
    if (Array.isArray(arr1) && Array.isArray(arr2)) {
        return arr1.concat(arr2);
    } else throw new Error("both variables should be an array!");
};

export const replaceNewlinesWithSpaces = (text) => {
    return text.replace(/\n/g, " ");
};

export const arraysAreEqual = (arr1, arr2) => {
    if(!Array.isArray(arr1) || !Array.isArray(arr2)) return false;

    if (arr1.length !== arr2.length) return false;

    return arr1.every((element, index) => element === arr2[index]);
};

export const formatPhoneNumber = (value) => {
    // Remove all non-numeric characters
    const numbersOnly = value.replace(/\D/g, "");

    // Format the phone number
    const match = numbersOnly.match(/^(\d{3})(\d{2})(\d{3})(\d{2})(\d{2})$/);
    if (match) {
        return `+${match[1]} (${match[2]}) ${match[3]}-${match[4]}-${match[5]}`;
    }
    return numbersOnly;
};
