import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { translate } from "../../../translations/translate";
import { ApiService } from "../../../utils/ApiService";
import { useLocation, useParams } from "react-router";
import { errorToast, successToast } from "../../../utils/toast";
import { useNavigate } from "react-router-dom";
import {parseFiscalID, parseVirtualID} from "../../../utils/helper.js";

import { Box } from "@mui/material";
import Button from "../../../components/global/Button";
import PageHeader from "../../../components/PageHeader";
import useApiHook from "../../../hooks/useApiHook";
import config from "../../../config";
import Preloader from "../../../components/Preloader";
import BreadCrumbs from "../../../components/BreadCrumbs";
import SelectComponent from "../../../components/Select";
import { fiscalPref } from "../../../hooks/fiscalPref";
import { PatternFormat } from "react-number-format";

import "./styles.sass";
import DragAndDrop from "../../DragAndDrop";

const PageFiscalEdit = () => {
    const { state } = useLocation();
    const { id } = useParams();
    const url = `${config.API_BASE_URL}/fiscalmodule/${
        state?.fiscal?.id || id
    }`;
    const { data, loading, error } = useApiHook(url);
    const navigate = useNavigate();

    const lang = useSelector((state) => state.languageReducer.lang);
    const __ = (key) => translate(lang, key);

    const Status = [
        {
            value: "reserved",
            label: __("Reserved"),
        },
        {
            value: "active",
            label: __("Active"),
        },
        {
            value: "free",
            label: __("Free"),
        },
        {
            value: "blocked",
            label: __("Blocked"),
        },
        {
            value: "defective",
            label: __("Defective"),
        },
        {
            value: "expired",
            label: __("Expired"),
        },
    ];

    const [dangerStatus, setDangerStatuses] = useState("");
    const [showField, setShowField] = useState(false);

    const handleStatusChange = (statusName) => {
        setDangerStatuses(statusName);
    };

    useEffect(() => {
        if (
            Status.slice(-3).find(
                (eachStatus) => eachStatus.value === dangerStatus,
            )
        ) {
            setShowField(true);
        } else {
            setShowField(false);
        }
    }, [dangerStatus]);

    const [equal, setEqual] = useState(true);
    const [newFiscalLetters, setNewFiscalLetters] = useState("");
    const [newFiscalNumber, setNewFiscalNumber] = useState("");
    const [fiscalInitial, setFiscalInitial] = useState({});
    const [fiscalCurrent, setFiscalCurrent] = useState({});
    const { token } = useSelector((state) => state.authReducer);
    const [ docDropdownOpen, setDocDropdownOpen ] = useState(false);

    useEffect(() => {
        if (data?.data?.docs) {
            data?.data?.docs?.map((fiscal) => {
                setFiscalInitial({
                    fiscalPref: fiscal.fiscalID.slice(0, 2),
                    fiscalNumbers: parseFiscalID(fiscal.fiscalID),
                    fiscalStatus: fiscal.status,
                    comment: fiscal.comment,
                    reason: fiscal.deactivation?.reason,
                    fiscalID: fiscal.fiscalID,
                    file: {
                        id: fiscal.file?._id,
                        name: fiscal.file?.fileInfo?.originalName
                    }
                });
                setFiscalCurrent({
                    fiscalPref: fiscal.fiscalID.slice(0, 2),
                    fiscalNumbers: parseFiscalID(fiscal.fiscalID),
                    fiscalStatus: fiscal.status,
                    comment: fiscal.comment,
                    reason: fiscal.deactivation?.reason,
                    fiscalID: fiscal.fiscalID,
                    file: {
                        id: fiscal.file?._id,
                        name: fiscal.file?.fileInfo?.originalName
                    }
                });
            });
        }
    }, [data]);

    useEffect(() => {
        if (
            fiscalInitial.fiscalPref === fiscalCurrent.fiscalPref &&
            fiscalInitial.fiscalNumbers === fiscalCurrent.fiscalNumbers &&
            fiscalInitial.fiscalStatus === fiscalCurrent.fiscalStatus &&
            fiscalInitial.comment === fiscalCurrent.comment &&
            fiscalInitial.reason === fiscalCurrent.reason &&
            fiscalInitial.fiscalID === fiscalCurrent.fiscalID &&
            fiscalInitial.file?.id === fiscalCurrent.file?.id
        ) {
            setEqual(true);
        } else {
            setEqual(false);
        }
    }, [fiscalCurrent, newFiscalLetters, newFiscalNumber]);

    // CALLBACK FUNCTIONS
    const toggleDocDropdown = () => {
        setDocDropdownOpen(prevState => !prevState);
    };

    const onCancelClick = function() {
        navigate("/fiscal");
    };

    const handleUploadedDoc = (doc) => {
        setFiscalCurrent({
            ...fiscalCurrent,
            file: {
                id: doc?.id,
                name: doc?.name,
            }
        });
    };

    const handleFiscalLettersChange = (e) => {
        setNewFiscalLetters(e.value);
        setFiscalCurrent({
            ...fiscalCurrent,
            fiscalPref: e.value
        });
    };

    const handleFiscalNumberChange = (values) => {
        const { value } = values;
        setNewFiscalNumber(value);
        setFiscalCurrent({
            ...fiscalCurrent,
            fiscalNumbers: value
        });
    };

    const onSubmit = (e) => {
        e.preventDefault();

        if(newFiscalNumber && newFiscalNumber.length !== 12) {
            document.getElementById("FiscalID").setCustomValidity("1");
            return;
        }

        if(newFiscalNumber && !newFiscalLetters) {
            errorToast(__("Choose fiscal letter"));
            return;
        }

        const body = {
            fiscalID:
                fiscalCurrent?.fiscalPref +
                fiscalCurrent?.fiscalNumbers.split(" ").join(""),
            status: fiscalCurrent?.fiscalStatus,
            comment: fiscalCurrent?.comment,
            deactivation: {
                reason: fiscalCurrent?.reason,
            },
            file: fiscalCurrent.file?.id
        };
        ApiService.patchEvent(`/fiscalmodule/${state?.id || id}`, token, body)
            .then((response) => {
                if (response.status === 200) {
                    successToast(__("Successfully updated"));
                    navigate(`/fiscal/${state?.id || id}`);
                }
            })

            .catch((error) => {
                errorToast(__(error.message));
            });
    };
    const buttons = [
        {
            name: "Back",
            link: "/fiscal",
            className: "outline",
        },
    ];
    useEffect(() => {
        data?.data?.docs?.map((item) => {
            setShowField(item?.deactivation?.reason);
        });
    }, [data]);
    if (loading)
        return (
            <div>
                <Preloader />
            </div>
        );
    if (error) return <div>{error.message}</div>;
    return (
        <div>
            <BreadCrumbs
                breadcrumbs={[
                    { label: "Fiscal", link: "/fiscal" },
                    { label: "Edit" },
                ]}
            />
            <div className="page component-page-fiscal-edit">
                <form onSubmit={onSubmit} className="page-create-inner">
                    <PageHeader
                        name={__("Edit Fiscal Module")}
                        buttons={buttons}
                    />
                    <div className="cards-inner">
                        <div className="left-side-cards">
                            {data?.data?.docs?.map((item, i) => (
                                <div key={i}>
                                    <div className="component-create-box-card">
                                        <div className="card-user">
                                            <div className="user-icon">
                                                <i className="icon-information" />
                                            </div>
                                            <div className="user-text">
                                                <p className="user-title">
                                                    {__(
                                                        "Fiscal Module Information",
                                                    )}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="input-inner">
                                            <div className="component-ui-input input-right input-item">
                                                <div className="component-switcher">
                                                    <label
                                                        className="ui-card-label"
                                                    >
                                                        <span className="ui-input-text">
                                                            {__("Old Fiscal ID")}
                                                        </span>
                                                        <input
                                                            defaultValue={item?.fiscalID?.slice(
                                                                0,
                                                                2,
                                                            )}
                                                            className="ui-input fiscal-id"
                                                            type="text"
                                                            disabled
                                                        />
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="component-ui-input">
                                                <label
                                                    className="ui-card-label"
                                                >
                                                    <input
                                                        defaultValue={parseFiscalID(
                                                            item?.fiscalID,
                                                        )}
                                                        className="ui-input fiscal-input"
                                                        type="text"
                                                        disabled
                                                    />
                                                </label>
                                            </div>

                                            <div className="component-ui-input input-item input-right">
                                                <div className="component-switcher">
                                                    <label
                                                        className="ui-card-label"
                                                    >
                                                <span className="ui-input-text">
                                                    {__("New Fiscal ID")}
                                                </span>
                                                        <SelectComponent
                                                            placeholder="UZ"
                                                            options={fiscalPref}
                                                            onChange={handleFiscalLettersChange}
                                                            value={
                                                                fiscalPref.find(
                                                                    (item) =>
                                                                        item.value === newFiscalLetters,
                                                                )
                                                            }
                                                            name="FiscalIDLetters"
                                                        />
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="component-ui-input">
                                                <label
                                                    className="ui-card-label"
                                                >
                                                    <PatternFormat
                                                        format="#### #### ####"
                                                        mask="_"
                                                        value={newFiscalNumber}
                                                        onValueChange={handleFiscalNumberChange}
                                                        className="ui-input fiscal-input"
                                                        placeholder="____ ____ ____"
                                                        onInvalid={e => e.target.setCustomValidity(__("Fiscal ID"))}
                                                        onInput={e => e.target.setCustomValidity("")}
                                                        id="FiscalID"
                                                    />
                                                </label>
                                            </div>
                                        </div>

                                        <div className="input-inner">
                                            <div className="component-ui-input">
                                                <label
                                                    className="ui-card-label"
                                                >
                                                    <input
                                                        defaultValue={parseVirtualID(
                                                            item?.virtualID,
                                                        )}
                                                        className="ui-input "
                                                        type="text"
                                                        disabled
                                                    />
                                                    <span className="input-text">{__("Virtual Number")}</span>
                                                </label>
                                            </div>

                                            <div className="component-ui-input">
                                                <label
                                                    className="ui-card-label"
                                                >
                                                    <span className="ui-input-text">
                                                        {__("Status")}
                                                    </span>
                                                    <SelectComponent
                                                        value={Status.find(
                                                            (status) =>
                                                                status?.value ===
                                                                fiscalCurrent?.fiscalStatus,
                                                        )}
                                                        options={Status}
                                                        onChange={(obj) => {
                                                            handleStatusChange(
                                                                obj.value,
                                                            );
                                                            setFiscalCurrent({
                                                                ...fiscalCurrent,
                                                                fiscalStatus:
                                                                obj.value,
                                                            });
                                                        }}
                                                        name="status"
                                                    />
                                                </label>
                                            </div>
                                        </div>

                                        <div className="files-block">
                                            <div className="file-row">
                                                <div className="file-card disabled">
                                                    <p className="text">{ fiscalCurrent.file?.name || "" }</p>
                                                    <p className="label">{__("Fiscal Document")}</p>
                                                </div>
                                                <Box sx={{display: "flex", width: "calc(50% - 12px)", gap: "16px"}}>
                                                    <div className="file-card" style={{maxWidth: "none"}}>
                                                        <input
                                                            value={__("Fiscal Document")}
                                                            disabled={true}
                                                            type="text"
                                                            className="text"
                                                        />
                                                    </div>
                                                    <div className="component-card-button">
                                                        {fiscalCurrent.file?.id
                                                            ? (
                                                                <button
                                                                    onClick={() => {
                                                                        setFiscalCurrent({
                                                                            ...fiscalCurrent,
                                                                            file: null
                                                                        });
                                                                    }}
                                                                    className="btn squared error"
                                                                >
                                                                    <i className="icon-delete"></i>
                                                                </button>
                                                            )
                                                            : (
                                                                <button
                                                                    type="button"
                                                                    onClick={() => {
                                                                        toggleDocDropdown();
                                                                    }}
                                                                    className="btn squared"
                                                                >
                                                                    <i className="icon-upload"></i>
                                                                </button>
                                                            )
                                                        }
                                                    </div>
                                                </Box>
                                            </div>
                                        </div>

                                        <div className="input-inner">
                                            <div className="component-ui-input">
                                                <label
                                                    className="ui-card-label"
                                                >
                                                    <input
                                                        defaultValue={item?.business?.name}
                                                        className="ui-input "
                                                        type="text"
                                                        disabled
                                                    />
                                                    <span className="input-text">{__("Business Name")}</span>
                                                </label>
                                            </div>

                                            <div className="component-ui-input">
                                                <label
                                                    className="ui-card-label"
                                                >
                                                    <input
                                                        defaultValue={item?.cashdesk?.uniqueID}
                                                        className="ui-input "
                                                        type="text"
                                                        disabled
                                                    />
                                                    <span className="input-text">{__("Cashdesk Key")}</span>
                                                </label>
                                            </div>
                                        </div>

                                        <div className="input-inner">
                                            {showField && (
                                                <div className="component-ui-input">
                                                    <label
                                                        className="ui-card-label"
                                                    >
                                                        <textarea
                                                            defaultValue={
                                                                item
                                                                    .deactivation
                                                                    ?.reason
                                                            }
                                                            onChange={(e) =>
                                                                setFiscalCurrent(
                                                                    {
                                                                        ...fiscalCurrent,
                                                                        reason: e
                                                                            .target
                                                                            .value,
                                                                    },
                                                                )
                                                            }
                                                            className="ui-input text-area"
                                                        />
                                                        <span className="ui-input-text">
                                                            {__(
                                                                "Reason of Deactivation",
                                                            )}
                                                        </span>
                                                    </label>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="component box-save">
                        <div className="not-save-item">
                            <div className="not-save-icon">
                                <i className="icon-saved" />
                            </div>
                            <div className="not-save-text">
                                <p className="save-text">
                                    {__("Not Saved Yet")}
                                </p>
                            </div>
                        </div>
                        <div className="not-save-button">
                            <Button
                                className="btn outline sm"
                                text={__("Cancel")}
                                onClick={onCancelClick}
                                type="button"
                            />
                            <Button
                                className="btn sm"
                                text={__("Save")}
                                type="submit"
                                disabled={equal}
                            />
                        </div>
                        {docDropdownOpen &&
                            <DragAndDrop
                                setter={handleUploadedDoc}
                                closeDragNDrop={toggleDocDropdown}
                                docModel={"User"}
                            />
                        }
                    </div>
                </form>
            </div>
        </div>
    );
};

export default PageFiscalEdit;
