import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import ModalWindow from "../../../components/ModalWindow";
import { Box, colors, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { translate } from "../../../translations/translate";
import { parseFiscalID } from "../../../utils/helper.js";
import StatusTag from "../../../components/StatusTag";
import Action from "../../../components/Action/index.jsx";
import BreadCrumbs from "../../../components/BreadCrumbs/index.jsx";
import Filter from "../../../components/Filter/index.jsx";
import useApiHook from "../../../hooks/useApiHook";
import config from "../../../config";
import Preloader from "../../../components/Preloader";
import "../../../assets/fonts/epos-icons/style.css";
import "./styles.sass";
import { updateTotalAction } from "../../../redux/reducers/paginationControllerReducer";
import Button from "../../../components/global/Button";
import DragAndDrop from "../../DragAndDrop";
import axios from "axios";
import { errorToast, successToast, warnToast } from "../../../utils/toast";

let baseUrl = `${config.API_BASE_URL}/fiscalmodule?`;

const PageFiscalTable = () => {
    const storedUserData = useSelector((state) => state.authReducer);
    const token = storedUserData.token;

    // STATE FROM ANOTHER PAGE
    const state = useLocation();

    const filters = {
        isCashdesk: true,
        isStatus: true,
    };
    const page = "fiscal";
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [importModalOpen, setImportModalOpen] = useState(false);
    const [isDragNDropOpen, setIsDragNDropOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [fiscalImportFile, setFiscalImportFile] = useState(null);
    const [partiallyUpload, setPartiallyUpload] = useState(null);
    const [searchInputValue, setSearchInputValue] = useState("");
    const [url, setUrl] = useState(baseUrl);
    const { data, loading, error } = useApiHook(url);

    const handleDragNDropOpen = () => {
        setIsDragNDropOpen(!isDragNDropOpen);
    };
    const handleImportFile = (file) => {
        setFiscalImportFile(file);
    };

    useEffect(() => {
        if (fiscalImportFile) {
            setIsLoading(true);
            axios
                .post(
                    `${config.API_BASE_URL}/fiscalmodule/import-fiscal`,
                    {
                        uploadType: "fiscal",
                        file: fiscalImportFile,
                    },
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                            Authorization: `Bearer ${token}`,
                        },
                    },
                )
                .then((result) => {
                    setIsLoading(false);
                    setPartiallyUpload({
                        fiscalImportData: result.data.data
                    });

                    if (result.data.data.failedValidationInstances?.length > 0) {
                      return warnToast(result.data.message || "File was partially uploaded");
                    }
                    successToast(__("File was successfully uploaded"));
                    setTimeout(() => window.location.reload(), 1000);
                })
                .catch((e) => {
                    setIsLoading(false);
                    if(e.response.status === 422) {
                        errorToast(__("Fiscal IDs and Virtual IDs should be unique"));
                        setPartiallyUpload({
                            fiscalImportData: e.response.data.data
                        });
                    } else {
                        errorToast(
                            __("Something went wrong. ") + __("Delete your file and try again") +
                            e.message,
                        );
                    }
                });
        }
    }, [fiscalImportFile]);

    useEffect(() => {
        if(searchInputValue)
            setUrl(`${config.API_BASE_URL}/fiscalmodule/search/fiscal?query=${searchInputValue}`);
        else setUrl(baseUrl);
    }, [searchInputValue]);

    const navigate = useNavigate();
    const [_, setId] = useState();

    const openModals = (id) => {
        setModalIsOpen(true);
        setId(id);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

    if (state?.state) {

        // FINDING IF STATUS WAS CHOSEN FOR FILTERING
        let status = "";
        state?.state?.status?.forEach(singleStatus => {
            if (singleStatus.isChecked) {
                status += `&${singleStatus.name.toLowerCase()}=true`;
            }
        });

        // CHECKING IF CASHDESK ASSIGNED FILTER WAS APPLIED
        let cashdeskAssigned = "";
        if (!state?.state?.cashdesk?.every(singleCashdesk => singleCashdesk.isChecked === true)) {
            state?.state?.cashdesk?.forEach(singleCashdesk => {
                if (singleCashdesk.name === "Assigned" && singleCashdesk.isChecked) {
                    cashdeskAssigned += "&cashdeskAssigned=true";
                } else if (singleCashdesk.name === "Not Assigned" && singleCashdesk.isChecked) {
                    cashdeskAssigned += "&cashdeskAssigned=false";
                }
            });
        }

        // CONCATENATING URL WITH FILTERS
        baseUrl += status + cashdeskAssigned;
    }

    const lang = useSelector((state) => state.languageReducer.lang);
    const __ = (key) => translate(lang, key);
    const controller = useSelector(
        (state) => state.paginationControllerReducer,
    );


    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(updateTotalAction(parseInt(data?.data?.totalDocs) || 0));
    }, [data]);

    useEffect(() => {
        setUrl(
            `${baseUrl}&page=${controller.page + 1}&limit=${controller.pageSize
            }`,
        );
    }, [controller.page, controller.pageSize]);

    if (loading)
        return (
            <div>
                <Preloader />
            </div>
        );
    if (error) return <div>{error}</div>;

    let fiscalModuleList = [];

    data?.data?.docs?.map((fiscal) => {
        let fiscalModuleSingle = {
            fiscalID: fiscal.fiscalID || "—",
            id: fiscal._id,
            status: fiscal.status,
            cashdeskID: fiscal.cashdesk?._id,
            cashdeskUniqueID: fiscal.cashdesk?.uniqueID,
        };
        fiscalModuleList.push(fiscalModuleSingle);
    });



    const columns = [
        {
            field: "fiscalId",
            headerName: __("Fiscal ID"),
            flex: 1,
            cellClassName: "mane-column--cell",
            renderCell: ({ row }) => {
                return (
                    <Box display="flex" justifyContent="center" >
                        <Link
                            to={`/fiscal/${row.id}`}
                            state={{
                                fiscal: {
                                    id: row.id,
                                },
                            }}
                        >
                            <Typography
                                fontFamily="Manrope, sans-serif"
                                fontWeight={600}
                                fontSize={14}
                                color={colors.common["black"]}
                            >
                                {row.fiscalID.slice(0, 2)}{" "}
                                {parseFiscalID(row.fiscalID)}
                            </Typography>
                        </Link>
                    </Box>
                );
            },
        },
        {
            field: "cashdesk",
            headerName: __("Cashdesk"),
            flex: 1,
            cellClassName: "mane-column--cell",
            renderCell: ({ row }) => {
                return (
                    <Box display="flex" justifyContent="center">
                        <Link to={`/cashdesks/${row.cashdeskUniqueID}`}>
                            <Typography
                                fontFamily="Manrope, sans-serif"
                                fontWeight={600}
                                fontSize={14}
                                color={colors.common["black"]}
                            >
                                {row.cashdeskUniqueID || __("Not assigned")}
                            </Typography>
                        </Link>
                    </Box>
                );
            },
        },
        {
            field: "status",
            headerName: __("Status"),
            type: "string",
            flex: 1,
            renderCell: (props) => {
                return (
                    <Box display="flex" justifyContent="center">
                        <StatusTag
                            className={`ui-status sm ${props.value === "blocked"
                                    ? "question"
                                    : "active"
                                }`}
                            text={props.value}
                        />
                    </Box>
                );
            },
        },
        {
            field: "action",
            headerName: __("Action"),
            type: "string",
            renderCell: ({ row }) => {
                return (
                    <Box display="flex" justifyContent="center">
                        <Action
                            onClick={() =>
                                navigate(`/fiscal/${row.slug || row.id}/edit`, {
                                    state: {
                                        fiscal: {
                                            id: row.id,
                                            slug: row.slug,
                                        },
                                    },
                                })
                            }
                            onClickView={() =>
                                navigate(`/fiscal/${row.slug || row.id}`, {
                                    state: {
                                        fiscal: {
                                            id: row.id,
                                            slug: row.slug,
                                        },
                                    },
                                })
                            }
                            openModal={() => openModals(row?.id)}
                            deleteAction={false}
                        />
                    </Box>
                );
            },
        },
    ];


    return (
        <div>
            <BreadCrumbs breadcrumbs={[{ label: "Fiscal", item: "/fiscal" }]} />
            <div className="page page-table-fiscal">
                <Box>
                    <Filter
                        description={__("Fiscal Modules")}
                        buttons={[
                            {
                                text: __("Import Fiscal"),
                                onClick: () => {
                                    setImportModalOpen(true);
                                },
                            },
                            {
                                text: __("Add Fiscal"),
                                link: "/fiscal/create",
                                classNames: "outline",
                            },
                        ]}
                        filters={filters}
                        page={page}
                        search={searchInputValue}
                        setSearch={setSearchInputValue}
                    />
                    <Box
                        sx={{
                            "&": {
                                margin: "0",
                                height: "90vh",
                                minHeight: "350px",
                            },
                            "& .name-column--cell": {
                                color: colors.green[300],
                            },
                            "& .MuiDataGrid-footerContainer": {
                                borderTop: "none",
                            },
                        }}
                    >

                        <DataGrid
                            rows={fiscalModuleList}
                            columns={columns}
                            rowCount={controller.total}
                            page={controller.page}
                            pageSize={controller.pageSize}
                            {...config.TABLE_CONFIG(__)}
                     />
                    </Box>
                </Box>
                <Modal
                    className="delete-modal"
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                >
                    <div className="modal-bottom">
                        <ModalWindow
                            title={__("Are you sure?")}
                            text={__(
                                "Are you sure you want to permanently delete this fiscal module?",
                            )}
                            onClick={closeModal}
                        />
                    </div>
                </Modal>
                <Modal
                    className="delete-modal"
                    isOpen={importModalOpen}
                    onRequestClose={closeModal}
                >
                    <div className="modal-bottom">
                        <div className="component-modal-window">
                            <div className="modal-inner">
                                <div className="modal-title">
                                    <h1 className="modal-window-title">
                                        {__("Import of the Fiscal Modules")}
                                    </h1>
                                </div>
                                <div
                                    className="close-icon"
                                    onClick={() => {
                                        setImportModalOpen(false);
                                    }}
                                ></div>
                                <div className="modal-body">
                                    <div className="window-modal-text">
                                        <p className="window-text">
                                            {__(
                                                "Download the example of the Excel file for understanding the format of data to be imported",
                                            )}
                                        </p>
                                    </div>
                                    <div className="modal-file">
                                        <div className="file">
                                            <span className="file-icon"></span>
                                            <div className="file-text">
                                                <p className="text">
                                                    Example.xlsx
                                                </p>
                                                <p className="text-size">
                                                    27KB
                                                </p>
                                            </div>
                                        </div>

                                        <a
                                            href={`${config.API_HOST}/uploads/templates/FiscalsImportTemplate.xlsx`}
                                            download="kitty.jpg"
                                            className="download-link"
                                        >
                                            <i className="icon-download"></i>
                                        </a>
                                    </div>
                                </div>
                                <div className="btn-container">
                                    <Button
                                        className="btn outline sm"
                                        text={__("Cancel")}
                                        onClick={() =>
                                            setImportModalOpen(false)
                                        }
                                    ></Button>
                                    <Button
                                        className="btn sm"
                                        text={__("Continue")}
                                        onClick={() => {
                                            setImportModalOpen(false);
                                            setIsDragNDropOpen(true);
                                        }}
                                    ></Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
                {isDragNDropOpen && (
                    <DragAndDrop
                        setter={handleImportFile}
                        closeDragNDrop={handleDragNDropOpen}
                        isUpload={false}
                        isXLS={true}
                        partiallyUpload={partiallyUpload}
                        setPartiallyUpload={setPartiallyUpload}
                        isLoading={isLoading}
                    />
                )}
            </div>
        </div>
    );
};

export default PageFiscalTable;
