import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import Modal from "react-modal";
import { IMaskInput } from "react-imask";
import ModalWithInput from "../ModalWithInput";
import { translate } from "../../translations/translate";
import { errorToast } from "../../utils/toast";
import { formatPhoneNumber } from "../../utils/helper";
import {
    Menu,
    MenuItem,
    IconButton,
    Box,
} from "@mui/material";
import "./styles.sass";

const PhoneSelect = ({ phone, setPhone, options, handlePhonesChange }) => {
  const lang = useSelector((state) => state.languageReducer.lang);
  const __ = (key) => translate(lang, key);
  const [anchorEl, setAnchorEl] = useState(null);
  const ref = useRef(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [menuWidth, setMenuWidth] = useState(0);
  const [addModalIsOpen, setAddModalIsOpen] = useState(false);
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [editingPhoneIndex, setEditingPhoneIndex] = useState(null);

  const handleMenuOpen = () => {
      const inputWidth = ref.current.getBoundingClientRect().width;
      setMenuWidth(inputWidth);
      setAnchorEl(ref.current);
      setIsMenuOpen(true);
  };

  const handleMenuClose = () => {
      setAnchorEl(null);
      setIsMenuOpen(false);
  };

  const handleCloseModal = (modalStateSetter) => {
    setInputValue("");
    modalStateSetter(false);
  };

  const handleOpenAddModal = (event) => {
    event.stopPropagation();
    setAddModalIsOpen(true);
  };

  const handleAddPhone = () => {
      if (inputValue?.length === 13) {
          handlePhonesChange([
            ...options,
            inputValue
          ]);
          setInputValue("");
          setAddModalIsOpen(false);
      } else errorToast("Invalid phone number");
  };

  const handleOpenEditModal = (index) => {
    const phone = options[index];
    setInputValue(phone);
    setEditingPhoneIndex(index);
    setEditModalIsOpen(true);
  };

  const handleEditPhone = () => {
      if (inputValue?.length === 13) {
          const updatedOptions = [...options];
          updatedOptions[editingPhoneIndex] = inputValue;
          handlePhonesChange(updatedOptions);
          setInputValue("");
          setEditModalIsOpen(false);
      } else errorToast("Invalid phone number");
  };

  const handleDeletePhone = (index) => {
      const updatedOptions = options.filter((_, i) => i !== index);
      handlePhonesChange(updatedOptions);
  };

  return (
      <>
          <Box sx={{ maxWidth: "calc(50% - 12px)", width: "100%", marginTop: "24px" }} className="phone-select">
              <label className="phone-select__label" ref={ref}>
                <IMaskInput
                  mask="+998 (00) 000-00-00"
                  value={phone}
                  onAccept={(value) => setPhone(value)}
                />
                <span className="phone-select__title">{__("Phone Number")}</span>
                <div className="icons-container">
                  <IconButton
                    onClick={handleOpenAddModal}
                  >
                    <i className="icon-plus" />
                  </IconButton>
                  <span
                    style={{
                      width: 1,
                      height: 24,
                      backgroundColor: "var(--basic-black)",
                    }}
                  ></span>
                  <IconButton onClick={handleMenuOpen}>
                    <i className={`icon-chevron ${isMenuOpen && "chevron-down"}`} />
                  </IconButton>
                </div>
              </label>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                PaperProps={{
                    style: {
                        borderRadius: "4px",
                        width: menuWidth,
                    },
                }}
              >
                  {options?.length > 0 ? (
                    options.map((option, index) => (
                      <MenuItem
                        key={index}
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: "8px 16px",
                            "&:hover": {
                                backgroundColor: "#f0f0f0",
                            },
                        }}
                      >
                          <Box>{formatPhoneNumber(option)}</Box>
                          <Box>
                              <IconButton
                                size="small"
                                sx={{ marginRight: "8px" }}
                                onClick={() => {
                                  handleMenuClose();
                                  handleOpenEditModal(index);
                                }}
                              >
                                  <i className="icon-edit phone-select-icon" />
                              </IconButton>
                              <IconButton
                                size="small"
                                onClick={() => handleDeletePhone(index)}
                              >
                                <i className="icon-delete phone-select-icon" />
                              </IconButton>
                          </Box>
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem>{__("No additional phones")}</MenuItem>
                  )}
              </Menu>
          </Box>

          {/* Modals */}
          <Modal
            className="phone-select__modal"
            overlayClassName="phone-select__modal-overlay"
            isOpen={addModalIsOpen}
            onRequestClose={() => handleCloseModal(setAddModalIsOpen)}
            shouldCloseOnOverlayClick
          >
            <ModalWithInput
              title="Add new phone number"
              inputLabel="Phone Number"
              inputValue={inputValue}
              setInputValue={setInputValue}
              onClose={() => handleCloseModal(setAddModalIsOpen)}
              onConfirm={handleAddPhone}
            />
          </Modal>

          <Modal
              className="phone-select__modal"
              overlayClassName="phone-select__modal-overlay"
              isOpen={editModalIsOpen}
              onRequestClose={() => handleCloseModal(setEditModalIsOpen)}
              shouldCloseOnOverlayClick
          >
            <ModalWithInput
              title="Edit phone number"
              inputLabel="Phone Number"
              inputValue={inputValue}
              setInputValue={setInputValue}
              onClose={() => handleCloseModal(setEditModalIsOpen)}
              onConfirm={handleEditPhone}
            />
          </Modal>
      </>
  );
};

export default PhoneSelect;
